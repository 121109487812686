<template>
  <v-flex md4 sm12 :pr-0="!smAndDown" :pt-2="!smAndDown" :pa-5="!smAndDown" :pa-2="smAndDown">
      <MainNav v-if="!smAndDown" />
      <div class="headings">
          <h1 class="title-head">Sign Up</h1>
          <span class="alternative-link">
              or
              <router-link class="pink-link" :to="{ name: 'auth-sign-in' }">sign in to your account</router-link>
          </span>
      </div>

      <form id="register-form" @submit.prevent="submit">
          <v-alert :value="error" type="error" outlined>{{ error }}</v-alert>
          <br />
          <v-text-field
              label="First Name"
              v-model="firstName"
              name="firstName"
              v-validate="'required'"
              :error-messages="errors.first('firstName')"
          />
          <v-text-field
              label="Last Name"
              v-model="lastName"
              name="lastName"
              v-validate="'required'"
              :error-messages="errors.first('lastName')"
          />
          <v-text-field
              label="Email Address"
              v-model="email"
              name="email"
              v-validate="'required|email'"
              :error-messages="errors.first('email')"
          />
          <v-text-field
              label="Password (8 characters or more)"
              v-model="password"
              name="password"
              v-validate="'required|min:8'"
              :error-messages="errors.first('password')"
              type="password"
          />
          <v-flex class="terms-wrapper">
              <p class="terms">
                  <v-checkbox
                      class="terms__checkbox"
                      v-model="agreedToTermsOfService"
                      color="primary"
                  />
                  <span>I agree to </span>
                  <a @click="tosModalActive = true" class="pink-link">i911's Terms</a>
                  <span>and</span>
                  <a @click="privacyPolicyModalActive = true" class="pink-link">Privacy Policy</a>
                  <TosModal v-model="tosModalActive" @accepted="accepted" />
                  <PrivacyPolicyModal v-model="privacyPolicyModalActive" @accepted="accepted" />
              </p>
          </v-flex>
          <vue-recaptcha
              class="vue-recaptcha"
              @verify="receivedCaptcha"
              sitekey="6LcGiWsUAAAAAOj4hdNbyHq0yhjGMRAkDZd0lj46"
          />
          <v-btn type="submit" color="primary" large block :disabled="submitting">Sign Up</v-btn>
      </form>
  </v-flex>
</template>

<script lang="ts">
import Vue from 'vue'
import VueRecaptcha from 'vue-recaptcha'
import RegisterForm from './RegisterForm.vue'
import MainNav from './MainNav.vue'
import TosModal from '@/modals/TosModal.vue'
import PrivacyPolicyModal from '@/modals/PrivacyPolicyModal.vue'
import store from '@/store'
import { register } from '@/services/authentication'

export default Vue.extend({
  data() {
      return {
          tosModalActive: false,
          privacyPolicyModalActive: false,
          error: null,
          submitting: false,
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          agreedToTermsOfService: false,
          captchaToken: ''
      }
  },
  computed: {
      smAndDown(): boolean {
          return this.$vuetify.breakpoint.smAndDown
      }
  },
  methods: {
      accepted() {
          this.agreedToTermsOfService = true
      },
      receivedCaptcha(response: string) {
          this.captchaToken = response
      },
      async submit() {
          const isValid = await this.$validator.validateAll()
          if (isValid && !this.submitting) {
              try {
                  this.submitting = true
                  this.error = null
                  await store.dispatch('authentication/register', {
                      firstName: this.firstName,
                      lastName: this.lastName,
                      email: this.email,
                      password: this.password,
                      captchaToken: this.captchaToken,
                      agreedToTermsOfService: this.agreedToTermsOfService
                  })

                  if (store.state.authentication.currentUser) {
                      this.$router.push({
                          name: 'register-complete'
                      })
                  }
              // @ts-ignore
              } catch ({ response }) {
                  if (response && response.data && response.data.error) {
                      this.error = response.data.error
                  } else {
                      this.error = 'Something went wrong with your request'
                  }
              }
              this.submitting = false
          }
      }
  },
  components: {
      MainNav,
      VueRecaptcha,
      TosModal,
      PrivacyPolicyModal
  }
})
</script>

<style lang="scss" scoped>
.terms-wrapper {
  margin: -20px 0;
}

.terms {
  display: flex;
  align-items: center;
  justify-content: left;

  & > * {
      margin-right: 0.2em;
  }
}

.terms__checkbox {
  flex: none;
  margin-top: 20px;
}

.vue-recaptcha {
  margin-bottom: 1.3rem;
}
</style>