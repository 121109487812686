import router from '@/router'
import store from '@/store'
import { validateJWTExpiration } from '@/utils/jwt'
import axios from 'axios'

/**
 * Intercepts all axios responses looking for unauthorized errors.
 * Triggers a check for JWT expiration, and force logouts the user.
 * This allows an additional check for JWT's expiration on every server response.
 */
axios.interceptors.response.use(
    (response) => {
        return Promise.resolve(response)
    },
    (error) => {
        if (error.response.status === 401) {
            const response = error.response
            const isValid = validateJWTExpiration()
            if (!isValid || error.response.data.error === 'session-expired') {
                store.dispatch('authentication/logout')
                router.push({ name: 'auth-sign-in' })
            }
        }

        return Promise.reject(error)
    }
)
