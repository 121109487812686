<template>
    <v-dialog v-model="isModalShowing" persistent width="700">
        <template v-slot:activator="{ on }">
            <span v-on="on">
                <slot />
            </span>
        </template>
        <v-card data-test="confirm-cancel-modal">
            <v-card-title>
                <h2 class="headline">Are you sure?</h2>
            </v-card-title>
            <v-card-text>
                <p>
                    Ending tracking will disconnect you from the current session. You can reconnect to this session if
                    the caller is still connected, or if the session was active within the last 60 seconds.
                </p>
            </v-card-text>
            <v-card-actions>
                <v-btn text @click="cancelModal">CANCEL</v-btn>
                <v-spacer />
                <!-- Show download button only if screen width is greater than 960px -->
                <v-btn v-if="chatInitiated && isScreenLarge" @click="triggerDownload" color="primary">Download Chat</v-btn>
                <v-btn @click="reset" color="error" data-test="confirm-end-tracking-button">End Tracking</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue'
import store from '@/store'
import { editUser } from '@/services/users'
import { eventBus } from "@/main";

export default Vue.extend({
    data(): { isModalShowing: boolean; teamName: string; screenWidth: number } {
        return {
            isModalShowing: false,
            teamName: '',
            screenWidth: window.innerWidth
        }
    },
    created() {
        this.teamName = store.state.authentication.currentUser.companyName
        window.addEventListener('resize', this.updateScreenWidth)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateScreenWidth)
    },
    methods: {
        updateScreenWidth() {
            this.screenWidth = window.innerWidth
        },
        triggerDownload() {
            eventBus.$emit('downloadChatConvo')
        },
        openModal() {
            this.isModalShowing = true
        },
        cancelModal() {
            this.isModalShowing = false
        },
        async reset() {
            if (!store.state.authentication.currentUser.hasSeenWalkthrough) {
                const userId = store.state.authentication.currentUser.id
                await editUser(userId, { hasSeenWalkthrough: true })
                await store.dispatch('authentication/getCurrentUser')
            }
            eventBus.$emit('clearGeoJsonLayer')
            eventBus.$emit('restartCT')
            this.uploadToS3()
            store.dispatch('location/reset')
            window.location.reload()
        },
        uploadToS3() {
            if (['i911 Inc.', 'Province of Alberta'].includes(this.teamName)) {
                store.dispatch('location/uploadToS3');
            }
        }
    },
    computed: {
        chatInitiated() {
            return store.state.location.chatInitiated
        },
        isScreenLarge(): boolean {
            return this.screenWidth > 960
        }
    }
})
</script>