import { library } from '@fortawesome/fontawesome-svg-core'
import { faVuejs } from '@fortawesome/free-brands-svg-icons'
//https://fortawesome.com/sets/font-awesome-5-solid
/**
 * Import the desired icon,
 * add it to `library.add()`
 * add it to the icons list using `createIcon` and pass in the package prefix and name
 *
 * Use as <v-icon>$vuetify.icons.icon_name</v-icon>
 */
import {
    faChevronRight,
    faCircle,
    faCode,
    faLayerGroup,
    faMapMarkerAlt,
    faMountain,
    faRoad,
    faUser,
    faUserCircle,
    faUsers,
    faChevronLeft,
    faSearch,
    faCaretDown,
    faCheckSquare,
    faSortUp,
    faSortDown,
    faExclamationTriangle,
    faFileDownload,
    faEdit,
    faTrash,
    faArrowRight,
    faArrowLeft,
    faCaretUp,
    faTimes, 
    faCheckCircle,
    faRetweet,
    faAddressCard,
    faLock,
    faUserPlus,
    faSms,
    faDatabase,
    faEyeSlash,
    faEye
} from '@fortawesome/free-solid-svg-icons'
import { faSquare } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

// Vue.use(Vuetify)

const opts = {}

Vue.component('font-awesome-icon', FontAwesomeIcon)
library.add(
    faVuejs,
    faLayerGroup,
    faMapMarkerAlt,
    faRoad,
    faCircle,
    faMountain,
    faUserCircle,
    faUser,
    faUsers,
    faCode,
    faChevronRight,
    faChevronLeft,
    faSearch,
    faCaretDown,
    faCaretUp,
    faCheckSquare,
    faExclamationTriangle,
    faSquare,
    faSortUp,
    faSortDown,
    faFileDownload,
    faEdit,
    faTrash,
    faArrowRight,
    faArrowLeft,
    faTimes,
    faCheckCircle,
    faRetweet,
    faAddressCard,
    faLock,
    faUserPlus,
    faSms,
    faDatabase,
    faEyeSlash,
    faEye
)

const createIcon = (icon = []) => ({ component: FontAwesomeIcon, props: { icon } })

Vue.use(Vuetify)

export default new Vuetify({
    icons: {
        //@ts-ignore
        iconfont: 'faSvg',
        values: {
            layer_group: createIcon(['fas', 'layer-group']),
            map_marker_alt: createIcon(['fas', 'map-marker-alt']),
            road: createIcon(['fas', 'road']),
            circle: createIcon(['fas', 'circle']),
            mountain: createIcon(['fas', 'mountain']),
            user_circle: createIcon(['fas', 'user-circle']),
            user: createIcon(['fas', 'user']),
            users: createIcon(['fas', 'users']),
            code: createIcon(['fas', 'code']),
            chevron_right: createIcon(['fas', 'chevron-right']),
            search: createIcon(['fas', 'search']),
            check_square: createIcon(['fas', 'check-square']),
            square: createIcon(['far', 'square']),
            checkboxOn: createIcon(['fas', 'check-square']),
            checkboxOff: createIcon(['far', 'square']),
            caret_down: createIcon(['fas', 'caret-down']),
            exclamation_triangle: createIcon(['fas', 'exclamation-triangle']),
            error: createIcon(['fas', 'exclamation-triangle']),
            success: createIcon(['fas', 'check-circle']),
            file_download: createIcon(['fas', 'file-download']),
            edit: createIcon(['fas', 'edit']),
            delete: createIcon(['fas', 'trash']),
            next: createIcon(['fas', 'arrow-right']),
            prev: createIcon(['fas', 'arrow-left']),
            dropdown: createIcon(['fas', 'caret-down']),
            sort: createIcon(['fas', 'caret-up']),
            clear: createIcon(['fas', 'times']),
            reTweet: createIcon(['fas', 'retweet']),
            addressCard: createIcon(['fas', 'address-card']),
            lock: createIcon(['fas', 'lock']),
            user_plus: createIcon(['fas', 'user-plus']),
            cellphone_message: createIcon(['fas', 'sms']),
            database: createIcon(['fas', 'database']),
            eye_slash: createIcon(['fas', 'eye-slash']),
            eye: createIcon(['fas', 'eye'])
        }
    }
})
