<template>
  <v-app data-test="layout-admin">
    <v-navigation-drawer clipped app permanent>
      <v-list dense>
        <v-list-item
          v-for="(link, index) in sidebarLinks"
          :key="index"
          :to="link.to"
          v-if="isVisibleLink(link)"
        >
          <v-list-item-action>
            <v-icon small v-text="link.icon" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="link.title" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app color="#231f20" dark clipped-left>
      <LogoSmall />
      <h1 class="headline" style="margin-left:30px; font-size: 1.2rem !important;">{{ companyName }}</h1>
      <v-spacer />
      <v-menu offset-y style="display: flex; align-items: center; justify-content: flex-end;">
        <template v-slot:activator="{ on }">
          <div style="display: flex; align-items: center;">
            <span>{{ user.firstName }} {{ user.lastName }}</span>
            <v-btn v-on="on" large text icon>
              <v-icon large>$vuetify.icons.user_circle</v-icon>
            </v-btn>
          </div>
        </template>
        <v-list light>
          <v-list-item @click="backToApp">
            <v-list-item-title>Back to Map</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="this.premiere == 1" :to="{ name: 'overwatch' }">
            <v-list-item-title>Overwatch</v-list-item-title>
          </v-list-item>
          <v-list-item @click="toTraining">
            <v-list-item-title>Training Videos</v-list-item-title>
          </v-list-item>
          <v-list-item @click="signOut">
            <v-list-item-title>Sign Out</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <slot />
    </v-main>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue'
import store from '@/store'
import axios from 'axios'

export default Vue.extend({
  data() {
    return {
      companyName: null,
      sidebarLinks: [
        {
          to: { name: 'admin-users' },
          title: 'USERS',
          icon: '$vuetify.icons.user'
        },
        {
          to: { name: 'admin-verification-edit' },
          title: 'VERIFICATION',
          icon: '$vuetify.icons.lock'
        },
        {
          to: { name: 'admin-teams' },
          title: 'TEAMS',
          icon: '$vuetify.icons.users'
        },
        {
          to: { name: 'admin-metrics' },
          title: 'TEAM | USER METRICS',
          icon: '$vuetify.icons.addressCard'
        },
        {
          to: { name: 'admin-platform-metrics' },
          title: 'PLATFORM METRICS',
          icon: '$vuetify.icons.database'
        },
        {
          to: { name: 'admin-overwatch' },
          title: 'OVERWATCH',
          icon: '$vuetify.icons.search'
        },
        {
          to: { name: 'admin-dev' },
          title: 'DEV',
          icon: '$vuetify.icons.code'
        },
      ],
      classic: 0,
      premiere: 0,
      enterprise: 0,
    }
  },
  created() {
    this.companyName = store.state.authentication.currentUser.companyName
  },
  methods: {
    signOut() {
      store.dispatch('authentication/logout')
      this.$router.push({
        name: 'auth-sign-in'
      })
    },
    toTraining() {
      this.$router.push({
        name: 'training'
      })
    },
    backToApp() {
      this.$router.push({ name: 'dashboard' })
    },
    isVisibleLink(link) {
      const teamName = store.state.authentication.teams[0].teamName
      axios.get(`/api/v1/teams/checkPermissionLevel/${teamName}/premiere`) .then(res => {
          this.premiere = res.data //returns either a 1 or 0 (T/F)
      })
      axios.get(`/api/v1/teams/checkPermissionLevel/${teamName}/enterprise`) .then(res => {
          this.enterprise = res.data //returns either a 1 or 0 (T/F)
      })

      if (link.title === 'VERIFICATION') {
        return this.companyName === 'i911 Inc.'
      } else if (link.title === 'DEV') {
        return this.companyName === 'i911 Inc.'
      } else if (link.title === 'OVERWATCH') {
        return this.premiere 
      } else if (link.title === 'TEAM | USER METRICS') {
        return this.enterprise
      } else if (link.title === 'PLATFORM METRICS') {
        return this.companyName === 'i911 Inc.'
      }
      return true
    },
  },
  computed: {
    user() {
      return store.state.authentication.currentUser
    },
  }
  
})
</script>