import axios from 'axios'
//@ts-ignore
import localStore from 'store'
//@ts-ignore
import * as decode from 'jwt-decode';

export function clearJWT(): void {
    localStore.remove('jwt')
    delete axios.defaults.headers.common['Authorization']
}

export function setJWT(token: string): void {
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        localStore.set('jwt', token)
    } else {
        clearJWT()
    }
}

export function getJWT(): string {
    return localStore.get('jwt')
}

export function validateJWTExpiration(): boolean {
    const jwt = getJWT()
    if (!jwt) {
        return false
    }

    const token = decode.jwtDecode(jwt)
    const currentTime = Date.now() / 1000

    return currentTime < token.exp
}
