<template>
  <v-dialog v-model="isModalShowing" persistent width="415">
    <template v-slot:activator="{ on }">
        <span v-on="on">
            <slot />
        </span>
    </template>
    <v-card data-test="call-list-modal">
      <v-card-title>
        <h4 class="">Tracking Lists</h4>
        <br />
      </v-card-title>
      <v-divider></v-divider>
      <div style="display: flex; justify-content: center; align-items: center; flex-direction: column; padding: 3% 0 3% 0;">
        <v-row>
          <input type="radio" 
            name="lang" 
            checked="checked" 
            v-model="picked"
            id="en" 
            value="English">
          <label class="language" for="en">English</label>
          <br>
          <input type="radio" 
            name="lang" 
            checked="" 
            v-model="picked"
            id="sp" 
            value="Spanish">
          <label class="language" for="sp">Spanish</label>
          <br>
          <input type="radio" 
            name="lang" 
            checked="" 
            v-model="picked"
            id="fr" 
            value="French">
          <label class="language" for="fr">French</label>
          <br>
          <input type="radio" 
            name="lang" 
            checked="" 
            v-model="picked"
            id="zh" 
            value="Mandarin">
          <label class="language" for="zh">Mandarin</label>
          <br>
        </v-row>
      </div>
      <v-divider></v-divider>
      <v-card-title>
        <h6 class="">Pick list to track</h6> <big>&#10606;</big>
      </v-card-title>
      <v-container style="overflow-y: scroll; height: 200px;">
        <v-list-item 
          class="numbersBox"
          v-for="(list, i) in callListTeams"
            :key="i"
            :value="list"
            active-color="primary"
          >
          <div class="checkbox">
            <input type="checkbox" v-bind:value="list.id" v-model="listId" @click="toggleTeam">
              {{ list["callListName"] }}
          </div>
        </v-list-item>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text @click="cancelModal">CANCEL</v-btn>
          <v-spacer />
        <v-btn @click="submitNumbers" color="primary">Send SMS'</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue'
import store from '@/store'
import axios from 'axios'
import { getCallListsByTeam, getTeamNumbers } from '@/services/call-list'
import {eventBus} from "@/main";
import { timeStamp } from 'console';

export default Vue.extend({
  data(): {
      isModalShowing: boolean
      callListTeams: any
      newLists: string
      clTeamId: number
      teamId: number
      listId: any
      isClicked: boolean
      numbers: any[]
    } {
      return {
        isModalShowing: false,
        callListTeams: [],
        newLists: '',
			  clTeamId: 0,
        teamId: 0,
        listId: [],
        isClicked: false,
        numbers: []
      }
  },
  created() {
    this.teamId = store.state.authentication.teams[0]["id"];
    this.getCallListsByTeam();
  },
  methods: {
    openModal() {
      this.isModalShowing = true
    },
    cancelModal() {
      this.isModalShowing = false
      window.location.reload()
    },
    async submitNumbers() {
      store.commit('location/callList', this.numbers)
      store.dispatch('location/requestMultipleLocations')
      eventBus.$emit('startNumbersSearch')
      eventBus.$emit('getDeepLLanguage')
      this.isModalShowing = false
    },
    async getCallListsByTeam() {
      const { data } = await getCallListsByTeam(this.teamId)
      this.callListTeams = data
    },
    async commitListNumbers() {
      const { data }  = await getTeamNumbers(this.listId)
      for (let [k, v] of Object.entries(data)) {
        this.numbers.push(data[k]["phoneNumber"])
      }
    },
    toggleTeam() {
      this.isClicked = !this.isClicked
    }
  },
  watch: {
    listId(oldVal, newVal) {
      if(newVal !== null && this.isClicked) {
        eventBus.$emit('addUserList', this.listId)
        this.commitListNumbers()
      }
      else if(oldVal !== null && newVal === null) {
        this.listId = ''
      }
      else{
        null
      }
    }
  },
  computed: {
    picked: {
      get(): string {
        return store.state.location.language
      },
      set(value: string) {
        store.commit(`location/setLanguage`, value)
      }
    },
  }
})
</script>

<style scoped>

.input-group-field {
  width: 100%;
  max-width: 45rem;
  height: 40px;
  padding: 1em;
  margin: 1em auto;
  background-color: lightgray;
  color: black;
  box-shadow: 0px .25rem 1rem rgba(black, .50);
  border-radius: 5px;
  outline: none;
}

.lists {
  font-size: 15px !important;
  text-align: center;
  font-weight: 500;
  color: black !important;
}

.v-dialog>.v-card>.v-card__text {
  padding: 0 24px 0 24px;
}

</style>