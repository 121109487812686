import axios from 'axios'
import { ConnectionRequest } from '../../../server/src/connection_request/connection-request.entity'

export interface RequestLocationPayload {
    connectionRequestUuid: string
    language: string
}
interface RequestLocationPayloadResponse {
    uuid: string
    phoneNumber: string
    id: number
    token: string
}

interface ActiveSessionFlag {
    flag: boolean
    connectionRequestUuid: string
    sessionOwner: string
    requestingUser: string
    originalStreamUuid: string
    requestStreamUuid: string
}

export function requestLocation(payload: RequestLocationPayload) {
    return axios.post<RequestLocationPayloadResponse>(`/api/v1/location/request-location`, payload)
}

export interface RequestTextMessageLocationPayload {
    connectionRequestUuid: string
    phoneNumber: string
}
export function requestTextMessageLocation(payload: RequestLocationPayload) {
    return axios.post<RequestLocationPayloadResponse>(`/api/v1/location/request-text-message-location`, payload)
}

export interface requestMultipleLocationsPayload {
    connectionRequestUuid: string
    callList: any
    language: string
}
export function requestMultipleLocationsByTextMessage(payload: requestMultipleLocationsPayload) {
    return axios.post<RequestLocationPayloadResponse>(`api/v1/location/request-text-message-multiple-locations`, payload)
}

export interface requestNewNumbersPayload {
    connectionRequestUuid: string
    newNumbers: any
    language: string
}
export function requestNewNumberLocations (payload: requestNewNumbersPayload) {
    return axios.post<RequestLocationPayloadResponse>(`api/v1/location/request-text-message-additional-locations`, payload)
}

export function checkForActiveSession(payload: RequestTextMessageLocationPayload){
    return axios.post<ActiveSessionFlag>('/api/v1/location/check-for-active-session', payload)
}

export interface CaseTransferRequestPayload{
    connectionRequestUuid: string
    socketID: string
}

export function caseTransferRequest(payload: CaseTransferRequestPayload) {
    return axios.post(`/api/v1/location/case-transfer-request`, payload)
}

export interface checkTransferPayload{
    connectionRequestUuid : string
    phoneNumber: string
}

export function checkCaseTransfer(payload: checkTransferPayload){
    return axios.get<RequestLocationPayloadResponse>('/api/v1/location/check-transfer')
}
//export function markTransfer(payload: checkTransferPayload){
//    return axios.post<RequestLocationPayloadResponse>('api/v1/location/mark-transfer', payload)
//}
export interface StartSessionPayload {
    phoneNumber: string
}

export const startSession = (payload: StartSessionPayload) => axios.post('/api/v1/location/start-stream', payload)


export interface StartOverwatchSessionPayload {
    phoneNumbers: any
}

export const startOverwatchSession = (payload: StartOverwatchSessionPayload) => axios.post('/api/v1/location/start-overwatch-stream', payload)

/* interface CurrentUser {

}
export function checkCases(){
    return axios.get<CurrentUser>(`/api/v1/auth/current-user`)
} */

export const getAllActiveRequests = () => axios.get('/api/v1/location/get-all-active-requests') 
