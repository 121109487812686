<template>
    <v-dialog v-model="isModalShowing" persistent width="415">
        <template v-slot:activator="{ on }">
            <span v-on="on">
                <slot />
            </span>
        </template>
        <v-card data-test="call-list-modal">
            <v-card-title>
                <h4 class="">Tracking Numbers</h4>
            </v-card-title>
            <v-card-text>
                <input 
                    type="text"
                    class="input-group-field"
                    v-model="newNumbers"
                    @keyup.enter="addNumber"
                    placeholder="Add Number and Press Enter"
                    style="font-weight: bold;"
                >
            </v-card-text>
            <div style="display: flex; justify-content: center; align-items: center; flex-direction: column; padding-bottom: 2%;">
                <v-row>
                    <input type="radio" 
                        name="lang" 
                        checked="checked" 
                        v-model="picked"
                        id="en" 
                        value="English">
                    <label class="language" for="en">English</label>
                    <br>
                    <input type="radio" 
                        name="lang" 
                        checked="" 
                        v-model="picked"
                        id="sp" 
                        value="Spanish">
                    <label class="language" for="sp">Spanish</label>
                    <br>
                    <input type="radio" 
                        name="lang" 
                        checked="" 
                        v-model="picked"
                        id="fr" 
                        value="French">
                    <label class="language" for="fr">French</label>
                    <br>
                    <input type="radio" 
                        name="lang" 
                        checked="" 
                        v-model="picked"
                        id="zh" 
                        value="Mandarin">
                    <label class="language" for="zh">Mandarin</label>
                    <br>
                </v-row>
            </div>
            <v-divider></v-divider>
            <v-container style="overflow-y: scroll; height: 300px;">
                <v-list-item 
                    class="numberListBox"
                    v-for="(number, i) in numbers"
                        :key="i"
                        :value="number"
                        active-color="primary"
                    >
                    <v-list-item class="numberList">{{ number }}</v-list-item>
                    <v-btn class="removeNumber" color="error" x-small @click="removeNumber(number)">x</v-btn>
                </v-list-item>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
                <v-btn text @click="cancelModal">CANCEL</v-btn>
                <v-spacer />
                <v-btn @click="commitAndSubmitNumbers" color="primary">Send SMS'</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="js">
import Vue from 'vue'
import store from '@/store'
import {eventBus} from "@/main";

export default Vue.extend({
    data() {
        return {
            isModalShowing: false,
            numbers: [],
            newNumbers: '',
        }
    },
    methods: {
        openModal() {
            this.isModalShowing = true
        },
        cancelModal() {
            this.isModalShowing = false
            window.location.reload()
        },
        addNumber() {
             if (this.numbers) {
                 if(this.newNumbers[0] == '+'){ //if international
                    this.numbers.push(
                        this.newNumbers,
                    );
                 }
                else{ //else domestic
                    this.newNumbers = '+1' + this.newNumbers
                    this.numbers.push(
                        this.newNumbers
                    )
                }
                this.newNumbers = '';
            }
        },
        removeNumber(number) {
            const index = this.numbers.indexOf(number);
            if (index !== -1) {
                this.numbers.splice(index, 1);
            }
        },
        commitAndSubmitNumbers() {
            eventBus.$emit('startNumbersSearch', this.numbers)
            store.commit('location/callList', this.numbers)
            store.dispatch('location/requestMultipleLocations')
            eventBus.$emit('getDeepLLanguage')
            this.isModalShowing = false
        },
    },
    computed: {
        picked: {
            get() {
                return store.state.location.language
            },
            set(value) {
                store.commit(`location/setLanguage`, value)
            }
        },
    }
})
</script>

<style>

.input-group-field {
    width: 100%;
    max-width: 45rem;
    height: 40px;
    padding: 1em;
    margin: 1em auto;
    background-color: lightgray;
    color: black;
    box-shadow: 0px .25rem 1rem rgba(black, .50);
    border-radius: 5px;
    outline: none;
}

.numberList {
    font-size: 15px;
    text-align: center;
    font-weight: 500;
}

.v-dialog>.v-card>.v-card__text {
    padding: 0 24px 0 24px;
}

.language {
    padding: 0 10px 0 4px;
    margin-top: 3px;
    font-weight: 500;
    font-size: 13px;
    text-transform: capitalize;
}

</style>
