<template>
    <div>
        <v-container pt-0 pb-0 mt-0 :ma-0="smAndDown" :pa-0="smAndDown">
            <v-layout class="hero-section" :wrap="smAndDown">
                <HeroLeft />
                <HeroRight />
            </v-layout>
        </v-container>
        <footer />
    </div>
</template>

<script lang="ts">
import Vue from 'vue'

import HeroLeft from './HeroLeft.vue'
import HeroRight from './HeroRight.vue'
import Footer from './Footer.vue'

export default Vue.extend({
    name: 'home',
    computed: {
        smAndDown(): boolean {
            return this.$vuetify.breakpoint.smAndDown
        }
    },
    components: {
        HeroLeft,
        HeroRight
    }
})
</script>

<style lang="scss" scoped>
.container {
    max-width: 1264px; // manually set to override Vuetify's limited container.
}

// First content area
.hero-section {
    height: 100vh; // keep this until adding more sections
    @media (max-width: 960px) {
        height: auto; // reset the above for mobile allowing scrolling.
    }
}
</style>
