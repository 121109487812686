import { validateJWTExpiration } from '@/utils/jwt'
import router from './index'
import store from '@/store'
import { isUscgEmail } from '../shared/utils'

router.beforeEach((to, from, next) => {
    const currentUser = store.state.authentication.currentUser
    const hasCompletedRegistration =
        currentUser && (currentUser.psapId || currentUser.companyName) && currentUser.phoneNumber
    const isAuthenticatedPage = to.meta && to.meta.auth
    const hasUnexpiredJWT = validateJWTExpiration()
    const isAdminPage = to.meta && to.meta.admin && to.meta.isTeamAdmin

    if (to.name === 'auth-sign-out') return next()
    // If logged in but has expired JWT, force logout
    if (currentUser && !hasUnexpiredJWT) store.dispatch('authentication/logout')

    // If not logged in and trying to access logged in page go to sign-in
    if (isAuthenticatedPage && !currentUser) return next({ name: 'auth-sign-in' })

    const isCoastGuard = currentUser && isUscgEmail(currentUser.email)
    // Teams in this case combines properties from the team and team_user entity. isActive refers to the team_user.
    const teamIsActive =
        store.state.authentication.teams && store.state.authentication.teams.some((team) => team.isActive)
    const isCoastGuardWithCompletedRegistration = teamIsActive && isCoastGuard

    // If logged in but hasn't completed registration AND COASTGUARD go to the coastguard signup page
    if (
        isCoastGuard &&
        !isCoastGuardWithCompletedRegistration &&
        isAuthenticatedPage &&
        to.name !== 'register-complete-uscg'
    )
        return next({ name: 'register-complete-uscg' })

    // if logged in but hasn't completed registration, go to the registration page
    if (
        !isCoastGuard &&
        currentUser &&
        isAuthenticatedPage &&
        !hasCompletedRegistration &&
        (to.name !== 'register-complete' && to.name !== 'register-complete-uscg')
    ) {
        return next({ name: 'register-complete' })
    }

    // If logged in and trying to access Admin page, but not admin, send to dashboard.
    if (currentUser && isAdminPage && (!currentUser.isAdmin || !currentUser.isTeamAdmin)) return next({ name: 'dashboard' })

    // if Logged in and trying to go to home or auth pages, redirect to dashboard
    if (currentUser && (to.name === 'home' || to.name.indexOf('auth') === 0)) return next({ name: 'dashboard' })

    next()
})
