<template>
  <div class="content-wrap">
    <img
      class="map-img"
      :src="require('@/assets/i911-map.png')"
      alt="A map showing someone's location next to a phone that has called 911"
    />
    <span v-if="!smAndDown" class="image-caption"
      >i911 locates all mobile phones (iPhone, Android, Windows, etc.)</span
    >
    <div v-if="smAndDown" class="slogan">
      <span class="headline">Accurately locate persons in distress.</span>
      <span class="subheading">Take the "search" out of search and rescue</span>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  computed: {
    smAndDown(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
});
</script>

<style lang="scss" scoped>
// Left side pink background content
.map-img {
  max-width: 100%;
  max-height: 75vh;
  width: auto;
  display: block;
  margin: auto;
}

.image-caption {
  display: block;
  margin-top: -2.5rem;
  max-width: 60%;
}

.slogan {
  margin-bottom: 1rem;
  margin-left: 3rem;
  * {
    display: block;
  }
  @media (max-width: 1264px) {
    margin-left: 1rem;
    .headline {
      font-size: 1.4em !important;
      font-weight: bold;
    }
    .subheading {
      font-size: 1rem !important;
    }
  }
  @media (max-width: 960px) {
    margin: 0.5rem 0 2rem;
    & > * {
      text-align: center;
    }
  }
}
</style>
