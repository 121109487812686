import io, { Socket } from 'socket.io-client'
export interface SocketServiceOptions {
    // server location
    url?: string
    // sub path for sockect connection
    path?: string
    // Connection token for socket server
    token?: string
}

class SocketService {
    socket: typeof Socket | null = null

    defaultOptions: SocketServiceOptions = {
        token: '',
        url: '',
        path: ''
    }

    connect(connectOptions: SocketServiceOptions) {
        const options = { ...this.defaultOptions, ...connectOptions }
        // @ts-ignore
        this.socket = io(options.url, {
            path: options.path,
            query: {
                token: options.token
            }
        })
    }

    join(channel: string){
        // @ts-ignore
        this.socket.emit('join', channel)
    }

    emit(channel: string, data: any) {
        // @ts-ignore
        this.socket.emit(channel, data)
    }

    on(channel: string, callback: Function): void {
        // @ts-ignore
        this.socket.on(channel, callback)
    }

    close() {
        if (this.socket) {
            // @ts-ignore
            this.socket.close()
            this.socket = null
        }
    }

    disconnect(){
        if (this.socket){
            this.socket = null
        }
    }
}

export default new SocketService()
