<template>
  <v-app>
    <v-app-bar app color="#231f20" dark>
      <v-layout justify-space-between fill-height align-center>
        <v-flex sm6 md3>
          <LogoSmall />
        </v-flex>
        <h1 class="headline" style="margin-right:15%">Training</h1>
        <v-flex justify-right sm3 md1>
          <v-menu offset-y style="display: flex; align-items: center; justify-content: flex-end;">
            <template v-slot:activator="{ on }">
              <div style="display: flex; align-items: center;">
                <span style="width: 350px; text-align: right;">{{ user.firstName }} {{ user.lastName }}</span>
                <v-btn v-on="on" large text icon>
                  <v-icon large>$vuetify.icons.user_circle</v-icon>
                </v-btn>
              </div>
            </template>

            <v-list light>
              <v-list-item @click="backToApp">
                <v-list-item-title>Back to Map</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="isAdminOrTeamAdmin === true" :to="{ name: 'admin' }">
                <v-list-item-title>Admin</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="isAdminOrTeamAdmin === true" :to="{ name: 'overwatch' }">
                <v-list-item-title>Overwatch</v-list-item-title>
              </v-list-item>
              <v-list-item @click="changeEmail">
                <v-list-item-title>Change Email</v-list-item-title>
              </v-list-item>
              <v-list-item  @click="">
                <v-list-item-title><a href="https://i911help.zendesk.com/hc/en-us/sections/360009792793-FAQ" target="_blank">Contact Support</a></v-list-item-title>
              </v-list-item>
              <v-list-item  @click="signOut">
                <v-list-item-title>Sign Out</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-flex>
      </v-layout>
    </v-app-bar>
    <v-main>
      <slot />
      <v-alert v-if="seen" class="alert"><p class="sms-text">SMS Received</p></v-alert>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue'
import store from '@/store'

// Must be high in the component hierarchy for correct DOM positioning.
import EndTrackingModal from '@/components/EndTrackingModal.vue'
import axios from "axios";
import {eventBus} from "@/main";

export default Vue.extend({
  data() {
    return {
      showErrorSnackbar: false,
      isHidden: false,
      seen: false
    }
  },
  methods: {
    backToApp() {
      this.$router.push({ name: 'dashboard' })
    },
    changeEmail() {
      store.commit('location/showEditEmailModal', true)
    },
    signOut() {
      store.dispatch('authentication/logout')
      store.dispatch('location/reset')
      // this.$tours.dashboardTour.stop()
      this.$router.push({
        name: 'auth-sign-in'
      })
    },
    toTraining() {
      this.$router.push({
        name: 'training'
      })
    },
    hideLocate() {
      this.isHidden = true
    },
  },
  computed: {
    phoneNumber: {
      get(): string {
        return store.state.location.phoneNumber
      },
      set(value: string) {
        store.commit('location/phoneNumber', value)
      }
    },
    isAdminOrTeamAdmin() {
      const user = store.state.authentication.currentUser
      return user && (user.isAdmin || user.isTeamAdmin)
    },
    smAndDown(): any {
      return this.$vuetify.breakpoint.smAndDown
    },
    user(): any {
      return store.state.authentication.currentUser
    },
  },
  components: {
    EndTrackingModal
  }
})
</script>
<style lang="scss" scoped>
.v-step {
  z-index: 100;
}

v-list-item-title {
  background-color: transparent;
  font-weight: unset;
}

.observe {
  background-color: red !important;
  color: white !important;
}

.alert {
  width: 100%;
  height: 5% !important;
  background-color:#FF0000 !important;
  border-radius:0 !important;
  padding-top: 10px !important;
}

.sms-text {
  color: white !important;
  font-weight: bold;
  font-size: 19px;
  text-align: center;
  //margin-bottom: 20px!important;
}

</style>
