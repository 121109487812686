<template>
    <v-app>
        <v-app-bar app color="#231f20" dark>
            <v-layout justify-space-between fill-height align-center>
                <v-flex sm6 md3>
                    <LogoSmall />
                </v-flex>
                <v-flex md6 v-if="!smAndDown">
                    <v-layout class="btn-span" justify-center align-center>
                        <!-- <div> -->
                        <form v-if="callListNumbers.length === 0">
                            <v-layout>
                                <span v-if="isActive===true" class="track-numbers-button">
                                    <FindByNumbersModal>
                                        <v-btn 
                                           outlined
                                           dark 
                                           data-test
                                           style="margin:12px 0 0 -2px;height:48px;"
                                        >Locate Numbers</v-btn>
                                    </FindByNumbersModal>
                                </span>
                                <span v-else class="track-line-button">
                                    <FindByListModal>
                                        <v-btn 
                                           outlined
                                           dark 
                                           data-test
                                           style="margin:12px 0 0 -2px;height:48px;"
                                        >Locate by List</v-btn>
                                    </FindByListModal>
                                </span>
                                <v-switch
                                    v-model="isActive"
                                    class="type-toggle"
                                    color="error"
                                ></v-switch>
                            </v-layout>
                            <v-snackbar
                                v-model="showErrorSnackbar"
                                :timeout="3000"
                                top
                                left
                                data-test="valid-phone-number-error-snackbar"
                                >Please use a valid Phone Number
                            </v-snackbar>
                        </form>
                        <template v-else>
                            <span style="margin-right:10px">Locating...</span>

                            <span class="end-tracking-container">
                                <EndOverwatchTrackingModal>
                                    <v-btn outlined dark data-test="end-tracking-button">End Tracking</v-btn>
                                </EndOverwatchTrackingModal>
                            </span>
                        </template>
                    </v-layout>
                    <!-- </div> -->
                </v-flex>
                <v-flex style="display: flex; align-items: center; justify-content: flex-end;" sm6 md3>
                    <template v-if="customHeaderLinks">
                        <v-btn
                            text
                            dark
                            v-for="(link, index) in customHeaderLinks"
                            :key="index"
                            :href="link.link"
                            target="_blank"
                        >{{ link.name }}</v-btn>
                    </template>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                            <div style="display: flex; align-items: center;">
                                <span>{{ user.firstName }} {{ user.lastName }}</span>
                                <v-btn v-on="on" large text icon>
                                    <v-icon large>$vuetify.icons.user_circle</v-icon>
                                </v-btn>
                            </div>
                        </template>
                        <v-list light>
                            <v-list-item @click="backToApp">
                                <v-list-item-title>Back to Map</v-list-item-title>
                            </v-list-item>  
                            <v-list-item v-if="isAdminOrTeamAdmin" :to="{ name: 'admin' }">
                                <v-list-item-title>Admin</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="toTraining">
                                <v-list-item-title>Training Videos</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="signOut">
                                <v-list-item-title>Sign Out</v-list-item-title>
                            </v-list-item>
                            <template v-if="customHeaderDropdownLinks">
                                <v-list-item
                                    v-for="(link, index) in customHeaderDropdownLinks"
                                    :key="index"
                                    :href="link.link"
                                    target="_blank"
                                >
                                    <v-list-item-title>{{ link.name }}</v-list-item-title>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-menu>
                </v-flex>
            </v-layout>
        </v-app-bar>
        <v-main>
            <v-alert v-if="seen" class="alert" :style="{ position: 'fixed', top: 50, zIndex:1 }"><p class="sms-text">SMS Received</p></v-alert>
            <slot />
        </v-main>
        <!-- <DashboardTour /> -->
    </v-app>
</template>

<script lang="ts">
import Vue from 'vue'
import store from '@/store'

// Must be high in the componnet heirarchy for correct DOM positioning.
import DashboardTour from '@/components/DashboardTour.vue'
import EndOverwatchTrackingModal from '@/components/EndOverwatchTrackingModal.vue'
import FindByNumbersModal from '@/components/FindByNumbersModal.vue'
import FindByListModal from '@/components/FindByListModal.vue'
import { eventBus } from '@/main'

export default Vue.extend({
    data() {
        return {
            showErrorSnackbar: false,
            isActive: true,
            seen: false
        }
    },
    methods: {
        backToApp() {
            this.$router.push({ name: 'dashboard' })
        },
        toggleDimensions() {
            this.isActive = !this.isActive;
        },
        signOut() {
            store.dispatch('authentication/logout')
            store.dispatch('location/reset')
            // this.$tours.dashboardTour.stop()
            this.$router.push({
                name: 'auth-sign-in'
            })
        },
        triggerSmsBanner() {
            this.seen = true;
            setTimeout(() => {
                this.seen = false;
            }, 3000);
        },
        toTraining() {
            this.$router.push({
                name: 'training'
            })
        },
    },
    computed: {
        phoneNumber: {
            get(): string {
                return store.state.location.phoneNumber
            },
            set(value: string) {
                store.commit('location/phoneNumber', value)
            }
        },
        log(): any {
            return store.state.location.log
        },
        smAndDown(): any {
            return this.$vuetify.breakpoint.smAndDown
        },
        callListNumbers(): any {
            return store.state.location.callListNumbers
        },
        user(): any {
            return store.state.authentication.currentUser
        },
        customHeaderDropdownLinks(): any {
            const teams = store.state.authentication.teams
            if (!teams || teams.length == 0) return []

            const team = teams[0]
            const links = team.customContent && team.customContent.links && team.customContent.links.headerDropdown
            if (links) return links
            return []
        },
        customHeaderLinks(): any {
            const teams = store.state.authentication.teams
            if (!teams || teams.length == 0) return []

            const team = teams[0]
            const links = team.customContent && team.customContent.links && team.customContent.links.header
            if (links) return links
            return []
        },
        isAdminOrTeamAdmin() {
            const user = store.state.authentication.currentUser
            return user && (user.isAdmin || user.isTeamAdmin)
        },
    },
    created() {
        eventBus.$on('triggerSmsBanner', () => {
            this.triggerSmsBanner()
        })
    },
    components: {
        EndOverwatchTrackingModal,
        FindByNumbersModal,
        FindByListModal
    }
})
</script>
<style lang="scss">

.btn-span {
    padding-left: 25% !important;
}

.v-step {
    z-index: 100;
}

.type-toggle {
    padding: 22px 0 0px 25px !important;
}

// .location-number-container {
//     @media (max-width: 1244px) {
//         position: relative;
//         float: left;
//         width: 70%;
//         top: 15px;
//         margin: 0;
//     }
// }

// .end-tracking-container {
//     @media (max-width: 1244px) {
//         position: relative;
//         float: right;
//         width: 30%;
//         margin: 0;
//     }
// }
</style>