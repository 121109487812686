<template>
    <v-dialog v-model="isModalShowing" persistent width="700">
        <template v-slot:activator="{ on }">
            <span v-on="on">
                <slot />
            </span>
        </template>
        <v-card data-test="confirm-cancel-modal">
            <v-card-title>
                <h2 class="headline">Are you sure?</h2>
            </v-card-title>
            <v-card-text>
                <!-- <p>
                    Ending Overwatch tracking will disconnect you from the current session. You can reconnect to this session if
                    the caller is still connected, or if the session was active within the last 60 seconds.
                </p> -->
            </v-card-text>
            <v-card-actions>
                <v-btn text @click="cancelModal">CANCEL</v-btn>
                <v-spacer />
                <v-btn @click="reset" color="error" data-test="confirm-end-tracking-button">End Tracking</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue'
import store from '@/store'
import { editUser } from '@/services/users'

export default Vue.extend({
    data() {
        return {
            isModalShowing: false
        }
    },
    methods: {
        openModal() {
            this.isModalShowing = true
        },
        cancelModal() {
            this.isModalShowing = false
        },
        async reset() {
            // this.$tours.dashboardTour.stop()
            // Since the user has gotten a location before mark as true.
            if (!store.state.authentication.currentUser.hasSeenWalkthrough) {
                const userId = store.state.authentication.currentUser.id

                await editUser(userId, {
                    hasSeenWalkthrough: true
                })
                await store.dispatch('authentication/getCurrentUser')
            }
            store.dispatch('location/reset')
            window.location.reload()
        }
    }
})
</script>