import { requestLocation, requestTextMessageLocation, startSession, caseTransferRequest, checkForActiveSession, checkCaseTransfer, requestMultipleLocationsByTextMessage, startOverwatchSession, requestNewNumberLocations} from '@/services/location-request'
import socket from '@/services/socket'
import { RootState } from '@/store'
import { ActionContext, Module } from 'vuex'
import { saveAs } from 'file-saver'
import { SOCKETIO_EVENTS } from '../../../server/src/stream/stream.constants'
import axios from 'axios'
import store from '@/store'
import authentication, { AuthenticationState, authenticationTypes } from './authentication'
// import { createImportSpecifier, validateLocaleAndSetLanguage } from 'typescript'

export interface Location {
    altitude: string
    address: string
    uncertaintyRadiusInMeters: number
    ipAddress: string
    id: number
    latitude: string
    longitude: string
    capturedFromSource: string
    dateTimeCaptured: string
    dateTimeStored: string
    timestamp: number
    socketId: string
    speed: number
}

export interface Log {
    // Log Message
    message: string
    // Logged time
    time: number
    // Maybe use to store icons
    icon?: string
}


export interface LocationState {
    path: string
    connectionRequest: any
    locations: Location[]
    s3Data: any
    status: string
    showSmsConfirmation: boolean
    phoneNumber: string
    log: Log[]
    callListNumbers: any
    newNumbersList: any
    callerConnected: boolean
    isTabFocused: boolean
    isSharingLocation: boolean
    locationType: string | null
    showWaitingForLocationModal: boolean
    showEmailVerificationModal: boolean
    showClearedDomainModal: boolean
    showEditEmailModal: boolean
    locationRequestError: string | null
    showCallTransferModal: boolean
    showAcceptCallTransferModal: boolean
    caseTransferUuid: string
    originalStreamUuid: string
    requestStreamUuid: string
    caseTransferStatus: boolean
    caseTransferWatcher: boolean
    showCaseTransferModal: boolean
    showFindByNumbersModal: boolean
    showFindByListModal: boolean
    showCreateCallListModal: boolean
    showAddUsersToCLModal: boolean
    showServiceAlertModal: boolean
    showAddNumbersModal: boolean
    showUserProfileModal: boolean
    checkCaseTransfer: boolean
    thisSocketToken: string
    caseTransferSocketToken: string
    locationInterval: ReturnType<typeof setInterval>
    dateTimeCompleted: boolean
    language: string
    deepLLanguage: string
    showA2PMessagingModal: boolean
    showA2PMobileMessagingModal: boolean
    showA2POverwatchModal: boolean
    chatInitiated: boolean
}

const LocationModule: Module<LocationState, RootState> = {
    namespaced: true,
    state: {
        connectionRequest: null,
        path: '',
        s3Data: [],
        locations: [],
        status: 'Not Connected',
        showSmsConfirmation: false,
        phoneNumber: '',
        log: [],
        callListNumbers: [],
        newNumbersList: [],
        callerConnected: false,
        isTabFocused: false,
        isSharingLocation: false,
        locationType: null,
        showWaitingForLocationModal: false,
        showEmailVerificationModal: false,
        showClearedDomainModal: false,
        showEditEmailModal: false,
        locationRequestError: null,
        showCallTransferModal: false,
        showFindByNumbersModal: false,
        showFindByListModal: false, 
        showCreateCallListModal: false,
        showAddUsersToCLModal: false,
        showAcceptCallTransferModal: false,
        showServiceAlertModal: true,
        showAddNumbersModal: false,
        showUserProfileModal: false,
        caseTransferStatus: false,
        caseTransferWatcher: false,
        caseTransferUuid: '',
        originalStreamUuid: '',
        requestStreamUuid: '',
        showCaseTransferModal: false,
        checkCaseTransfer: false,
        thisSocketToken: '',
        caseTransferSocketToken: '',
        dateTimeCompleted: false,
        locationInterval: null,
        language: 'English',
        deepLLanguage: 'Off',
        showA2PMessagingModal: false,
        showA2PMobileMessagingModal: false,
        showA2POverwatchModal: false,
        chatInitiated: false
    },
    mutations: {
        connectionRequest(state, connectionRequest) {
            state.connectionRequest = connectionRequest
        },
        reset(state) {
            state.path = ''
            state.locations = []
            state.status = 'Not Connected'
            state.showSmsConfirmation = false
            state.phoneNumber = ''
            state.log = []
            state.callListNumbers = []
            state.newNumbersList = []
            state.callerConnected = false
            state.isTabFocused = false
            state.isSharingLocation = false
            state.locationType = null
            state.showWaitingForLocationModal = false
            state.showEmailVerificationModal = false
            state.showClearedDomainModal = false
            state.showEditEmailModal = false
            state.locationRequestError = null
            state.showCallTransferModal = false
            state.showFindByNumbersModal = false
            state.showFindByListModal = false
            state.showCreateCallListModal = false
            state.showAddUsersToCLModal = false
            state.showAcceptCallTransferModal = false
            state.showServiceAlertModal = true
            state.showAddNumbersModal = false
            state.showUserProfileModal =false
            state.caseTransferStatus = false
            state.caseTransferWatcher = false
            state.caseTransferUuid = ''
            state.originalStreamUuid = ''
            state.requestStreamUuid = ''
            state.showCaseTransferModal = false
            state.checkCaseTransfer = false
            state.thisSocketToken = ''
            state.caseTransferSocketToken = ''
            state.dateTimeCompleted = false
            state.language = 'English'
            state.deepLLanguage = 'Off'
            clearInterval(state.locationInterval)
            state.showA2PMessagingModal = false
            state.showA2PMobileMessagingModal = false
            state.showA2POverwatchModal =false
            state.chatInitiated = false
        },
        addLocation(state, location: Location) {
            state.locations.push(location)
            state.s3Data.push(location)
        },
        addS3Data(state, location: Location) {
            state.s3Data.push(location)
        },
        showSmsConfirmation(state, show: boolean) {
            state.showSmsConfirmation = show
        },
        phoneNumber(state, phoneNumber: string) {
            state.phoneNumber = phoneNumber
        },
        path(state, path) {
            state.path = path
        },
        status(state, newStatus: string) {
            state.status = newStatus
        },
        log(state, entry: string) {
            state.log.push({
                message: entry,
                time: Date.now()
            })
        },
        callList(state, callList: any) {
            state.callListNumbers = callList
        },
        newNumbers(state, newNumbers: any) {
            state.newNumbersList = newNumbers
        },
        callerConnected(state, isConnected: boolean) {
            state.callerConnected = isConnected
        },

        isTabFocused(state, value: boolean) {
            state.isTabFocused = value
        },
        isSharingLocation(state, value: boolean) {
            state.isSharingLocation = value
        },
        locationType(state, value: string) {
            state.locationType = value
        },
        showWaitingForLocationModal(state, value: boolean) {
            state.showWaitingForLocationModal = value
        },
        showEmailVerificationModal(state, value: boolean) {
            state.showEmailVerificationModal = value
        },
        showClearedDomainModal(state, value: boolean) {
            state.showClearedDomainModal = value
        },
        showEditEmailModal(state, value: boolean) {
            state.showEditEmailModal = value
        },
        locationRequestError(state, value: string | null) {
            state.locationRequestError = value
        },
        showCallTransferModal(state, value:boolean | null){
            state.showCallTransferModal = value
        },
        showAcceptCallTransferModal(state, value:boolean | null){
            state.showAcceptCallTransferModal = value
        },
        showFindByNumbersModal(state, value:boolean | null){
            state.showFindByNumbersModal = value
        },
        showFindByListModal(state, value:boolean | null){
            state.showFindByListModal = value
        },
        showCreateCallListModal(state, value:boolean | null){
            state.showFindByListModal = value
        },
        showServiceAlertModal(state, value:boolean | null){
            state.showServiceAlertModal = value
        },
        showAddNumbersModal(state) {
            state.showAddNumbersModal = !state.showAddNumbersModal
        },
        showAddUsersToCLModal(state, value:boolean | null){
            state.showAddUsersToCLModal = value
        },
        showCaseTransferModal(state, value:boolean | null){
            state.showCaseTransferModal = value
        },
        showUserProfileModal(state, show: boolean) {
            state.showUserProfileModal = show
        },
        changeCaseTransferUuid(state, value: string | null){
            state.caseTransferUuid = value
        },
        addPhoneNumber(state, value: string | null){
            state.phoneNumber = value
            console.log(value)
        },
        setLanguage(state, value:string){
            state.language = value
        },
        setDeepLLanguage(state, value:string){
            if(value == 'English'){
                state.deepLLanguage = 'Off' //default to no translation required
            }
            else if (value == 'Spanish'){
                state.deepLLanguage = 'es'
            }
            else if (value == 'French'){
                state.deepLLanguage = 'fr'
            }
            else if (value == 'Mandarin'){
                state.deepLLanguage = 'zh'
            }
        },
        showA2PMessagingModal(state, value: boolean){
            state.showA2PMessagingModal = value
        },
        showA2PMobileMessagingModal(state, value: boolean){
            state.showA2PMobileMessagingModal = value
        },
        showA2POverwatchModal(state, value: boolean){
            state.showA2POverwatchModal = value
        },
        chatInitiated(state, value:boolean | null){
            state.chatInitiated = value
        }
    },
    actions: {
        //depreciated -> should always fall to sms request
        //session is made within this function no matter what
        async requestLocation({ dispatch, commit, state, rootState }): Promise<any> {
            //if the first char is a +, convert phone value to international
            let phone = state.phoneNumber
            if (state.phoneNumber[0] == "+"){
                 phone = `${state.phoneNumber}`
            }
            else{
                phone = `+1${state.phoneNumber}`
            }
            commit('log', `Requested location for ${phone}`)

            try {
                const response = await startSession({ phoneNumber: phone })

                if (response.data.token) {
                    commit('connectionRequest', response.data)
                    await dispatch('startConnection', response.data.token)
                }
            } catch (e) {
                // @ts-ignore
                if (e.response && e.response.data.error) {
                    // @ts-ignore
                    const message = e.response.data.error
                    if (message === 'verify-email') return commit('showEmailVerificationModal', true)
                    if (message === 'unauthorized-domain') return commit('showClearedDomainModal', true)
                }
            }

            try {
                const requestRapidSosResponse = await requestLocation({
                    connectionRequestUuid: state.connectionRequest.uuid,
                    language: state.language
                })
                if (requestRapidSosResponse.data.uuid) {
                    commit('log', 'Able to automtatically retrieve location')

                    commit('locationType', 'RapidSOS')

                    return
                }
                commit('log', 'Unable to automatically retrieve location')
                commit('status', 'Unable to automatically retrieve location')
            } catch (e) {
                // @ts-ignore
                if (e.response && e.response.data.error) {
                    // @ts-ignore
                    commit('locationRequestError', e.response.data.error)
                }
                // @ts-ignore
                const message = e.response && e.response.data && e.response.data.error
                if (
                    message &&
                    (message === 'lookup-unavailable-no-team' ||
                        message === 'lookup-unavailable-not-active' ||
                        message === 'lookup-unavailable-invalid-keys' ||    
                        message === 'rapid-sos-unavailable')
                ) {
                    commit('locationRequestError', message)
                }
                // Fallback to SMS location
                commit('showSmsConfirmation', true)
                commit('log', 'Unable to automatically retrieve location')
                commit('status', 'Unable to automatically retrieve location')
            }
        },
        async requestMultipleLocations({ dispatch, commit, state }) {
            // Will need to create a version of this for multiple numbers
            const response = await startOverwatchSession({ phoneNumbers: state.callListNumbers })

            if (response.data.token) {
                commit('connectionRequest', response.data)
                await dispatch('startConnection', response.data.token)
            }

            // Initiate request SMS send
            requestMultipleLocationsByTextMessage({
                connectionRequestUuid: state.connectionRequest.uuid,
                callList: state.callListNumbers,
                language: state.language
            })
        },
        requestNewNumberLocations({ dispatch, state, commit }) {
            // Update callListNumbers in the state with newNumbersList
            const updatedCallListNumbers = [...state.callListNumbers, ...state.newNumbersList]
            commit('callList', updatedCallListNumbers)
            
            requestNewNumberLocations({
              connectionRequestUuid: state.connectionRequest.uuid,
              newNumbers: state.newNumbersList,
              language: state.language
            })
          },
        async requestLocationSms({ dispatch, commit, state }): Promise<any> {
            //if the first char is a +, convert phone value to international
            let phone = state.phoneNumber
            if (state.phoneNumber[0] == "+"){
                    phone = `${state.phoneNumber}`
            }
            else{
                phone = `+1${state.phoneNumber}`
            }
                commit('log', `Requested SMS location for ${phone}`)
                try {
                    //If RapidSOS fails, send text message request.
                    const response = await requestTextMessageLocation({
                        connectionRequestUuid: state.connectionRequest.uuid,
                        language: state.language
                    })
                    if (response.data.token) {
                        commit('log', 'Request for SMS location sent')
                        commit('locationType', 'Text')
                        commit('showWaitingForLocationModal', true)
                        return
                    }

                    commit('log', 'Unable to request SMS location')
                    commit('status', 'Unable to send SMS request for location')
                } catch (e) {
                    commit('log', 'Unable to request SMS location')
                    commit('status', 'Unable to send SMS request for location')
                    // @ts-ignore
                    if (e.response && e.response.data.error) {
                        // @ts-ignore
                        const message = e.response.data.error
                        if (message === 'verify-email') return commit('showEmailVerificationModal', true)
                        if (message === 'unauthorized-domain') return commit('showClearedDomainModal', true)
                    }
                }
           //}
        },
        // Initialize the socket connection
        startConnection({ dispatch, commit, state }, token: string) {
            state.caseTransferWatcher = true
            state.thisSocketToken = token
            commit('log', 'Connecting to server')
            socket.connect({ token })
            // axios.post(`api/v1/location/save-connect-socket/${state.connectionRequest.uuid}`)
            //     .then((response) => {
            //     })

            socket.on(SOCKETIO_EVENTS.NEW_LOCATION_AVAILABLE, (location: Location) => {
                // Don't add location if it's in the store
                const isLocationInStore = state.locations.find(
                    (existingLocation) => existingLocation.dateTimeCaptured === location.dateTimeCaptured
                )
               
                // Hide if reconnecting and locations come in.
                commit('showWaitingForLocationModal', false)
                commit('showSmsConfirmation', false)

                if (isLocationInStore) return

                if (location.capturedFromSource === 'TEXT_MESSAGE') commit('locationType', 'Text')
                if (location.capturedFromSource === 'RAPID_SOS') commit('locationType', 'RapidSOS')

                // For dispatcher reconnection while caller is already connected.
                //TODO: Upon reconnecting, all statuses will resolve as true, where not necessarily true..
                if (!state.callerConnected) {
                    commit('log', 'Caller connected')
                    commit('callerConnected', true)
                }
                commit('showWaitingForLocationModal', false)
                commit('log', 'New location received')
                commit('isSharingLocation', true) //Not triggered under some conditions
                commit('isTabFocused', true) //Not triggered under some conditions
                commit('addLocation', location)
            })

            socket.on('connect', (location: Location) => {
                commit('log', 'Connected to server')
                commit('status', 'Connected')
            })

            socket.on(SOCKETIO_EVENTS.CALLER_CONNECTED, (isConnected: Boolean) => {
                if (isConnected) {
                    commit('log', 'Caller connected')
                    commit('callerConnected', isConnected)
                } else {
                    commit('log', 'Caller disconnected')
                    commit('callerConnected', false)
                    commit('isTabFocused', false)
                    commit('isSharingLocation', false)
                }
            })

            socket.on(SOCKETIO_EVENTS.IS_TAB_FOCUSED, (value: Boolean) => {
                commit('isTabFocused', value)
            })

            socket.on(SOCKETIO_EVENTS.IS_SHARING_LOCATION, (value: Boolean) => {
                commit('isSharingLocation', value)
                if(value === false) {
                    commit('log', 'Caller Stopped Sharing')
                }
            })

            socket.on(SOCKETIO_EVENTS.LOCATION_SHARING_ERROR, (data: any) => {
                commit('log', `Caller unable to share location: ${data}`)
            })

            socket.on('connect_timeout', (location: Location) => commit('status', 'connect_timeout'))
            socket.on('disconnect', (location: Location) => commit('status', 'Disconnected'))
            socket.on('reconnect_attempt', (location: Location) => commit('status', 'Attempting to Reconnect'))
            socket.on('reconnect', (location: Location) => commit('status', 'reconnect'))
            socket.on('error', (location: Location) => commit('status', 'error'))
            socket.on('reconnect_error', (location: Location) => commit('status', 'Attempting to Reconnect'))
            socket.on('reconnect_failed', (location: Location) => commit('status', 'Unable to Reconnect'))
        },
        setDateTimeCompleted({state}){
            if(state.connectionRequest){
                axios.post(`api/v1/location/set-date-time-completed/${state.connectionRequest.uuid}`)
            }
        },
        closeConnection({ commit, state }, payload) {
            /*axios.get(`api/v1/location/get-date-time-completed/${state.connectionRequest.uuid}`)
            .then((response) => {
                state.dateTimeCompleted = response.data
            })
            if (state.caseTransferStatus == false){
                //close normally
                socket.close()
                commit('log', 'Connection closed')
            }
            else if (state.caseTransferStatus == true && state.dateTimeCompleted == false){
                //close if it was apart of a CT (first dispatcher)
                axios.post(`api/v1/location/participant-disconnect`)
                socket.disconnect() //socket is still open
            }
            else{
                //close if it was apart of CT (last dispatcher)
                socket.close()
            }*/
            socket.close()
            commit('log', 'Connection closed')
        },
        reset({ dispatch, commit}) {
            dispatch('setDateTimeCompleted')
            dispatch('closeConnection')
            commit('showWaitingForLocationModal', false)
            commit('reset')
        },
        downloadLocations({ state }) {
            const content = new Blob([JSON.stringify(state.locations)], { type: 'text/plain;charset=utf-8' })
            const fileName = `${state.phoneNumber}_${state.locations[0].dateTimeCaptured}.json`
            saveAs(content, fileName)
        },
        async uploadToS3({ state }) {
            const s3UploadData = state.s3Data.map((location) => ({
                Dispatcher: store.state.authentication.currentUser.firstName+' '+store.state.authentication.currentUser.lastName,
                PhoneNumber: state.phoneNumber,
                Longitude: location.longitude,
                Latitude: location.latitude,
                UncertaintyRadiusInMeters: location.uncertaintyRadiusInMeters.toFixed(2),
                Altitude: location.altitude.toFixed(4),
                Address: location.address,
                CapturedFromSource: location.capturedFromSource,
                CapturedAt: new Date(location.dateTimeCaptured).toUTCString()
            }));
    
            const teamId = store.state.authentication.teams[0].id
            await axios.post(`/api/v1/aws/upload-to-s3/${teamId}/${state.connectionRequest.uuid}`, s3UploadData)
        },
        async acceptCaseTransfer({dispatch, commit, state}) {
            commit('log', 'Accepted call transfer request')
            axios.post(`api/v1/location/mark-case-transfer-confirmation/${state.connectionRequest.uuid}/+1${state.phoneNumber}/${state.thisSocketToken}`)
            //mark in database that the confirmation is 1
            commit('reset')
        },
        async denyCaseTransfer({dispatch, commit, state}){
            commit('log', 'Denied call transfer request')
            axios.post(`api/v1/location/mark-transfer/${state.connectionRequest.uuid}/+1${state.phoneNumber}/${0}`)
            .then((response) => {
            });
            state.caseTransferStatus = false
            //mark in database that the confirmation is 0 
        },
        async checkTransfer({dispatch, commit, state}) {
            let phone = state.phoneNumber
            if (state.phoneNumber[0] == "+"){
                phone = `${state.phoneNumber}`
            }
            else{
                phone = `+1${state.phoneNumber}`
            }
            
            //want to return the connection and show the request on that user's screen
            if (state.caseTransferWatcher){
                
            axios.get(`api/v1/location/check-transfer/${state.connectionRequest.uuid}/${phone}`)
            .then((response) => {
                let caseTransfer = response.data
                if (caseTransfer == true){
                    state.caseTransferStatus = true
                    
                }
                else{
                    state.caseTransferStatus = false
                }
                    })
                
            }
           
        },
        async checkCaseTransferConfirmation({dispatch, commit, state}){
            let phone = state.phoneNumber
            if (state.phoneNumber[0] == "+"){
                phone = `${state.phoneNumber}`
            }
            else{
                phone = `+1${state.phoneNumber}`
            }
            axios.get(`api/v1/location/check-case-transfer/${state.caseTransferUuid}/${phone}`)
            .then((response) => {
                if (response.data){
                    state.caseTransferSocketToken = response.data
                    state.checkCaseTransfer = true
                    
                }
                else{
                    state.caseTransferSocketToken = ''
                    state.checkCaseTransfer = false
                }
            })
        },
        async connectToSocket({dispatch, commit, state}){
            let phone = state.phoneNumber
            if (state.phoneNumber[0] == "+"){
                 phone = `${state.phoneNumber}`
            }
            else{
                phone = `+1${state.phoneNumber}`
            }

            axios.post(`/api/v1/location/join-stream/${state.caseTransferUuid}/${phone}`)
            .then((response) => { //this grabs the session token from Dispatcher 1
                if (response.data.token) {
                    commit('connectionRequest', response.data)
                    let token = response.data.token
                    socket.connect({ token }) 
                    //socket.join(response.data.token)

                    socket.on(SOCKETIO_EVENTS.NEW_LOCATION_AVAILABLE, (location: Location) => {
                        // Don't add location if it's in the store
                        const isLocationInStore = state.locations.find(
                            (existingLocation) => existingLocation.dateTimeCaptured === location.dateTimeCaptured
                        )
        
                        if (isLocationInStore) return
        
                        if (location.capturedFromSource === 'TEXT_MESSAGE') commit('locationType', 'Text')
                        if (location.capturedFromSource === 'RAPID_SOS') commit('locationType', 'RapidSOS')
        
                        // For dispatcher reconnection while caller is already connected.
                        //TODO: Upon reconnecting, all statuses will resolve as true, where not necessarily true..
                        if (!state.callerConnected) {
                            commit('log', 'Caller connected')
                            commit('callerConnected', true)
                        }
                        commit('log', 'New location received')
                        commit('isSharingLocation', true) //Not triggered under some conditions
                        commit('isTabFocused', true) //Not triggered under some conditions
                        commit('addLocation', location)
                    })
                    socket.on('connect', (location: Location) => {
                        commit('log', 'Connected to server')
                        commit('status', 'Connected')
                    })
        
                    socket.on(SOCKETIO_EVENTS.CALLER_CONNECTED, (isConnected: Boolean) => {
                        if (isConnected) {
                            commit('log', 'Caller connected')
                            commit('callerConnected', isConnected)
                        } else {
                            commit('log', 'Caller disconnected')
                            commit('callerConnected', false)
                            commit('isTabFocused', false)
                            commit('isSharingLocation', false)
                        }
                    })
        
                    socket.on(SOCKETIO_EVENTS.IS_TAB_FOCUSED, (value: Boolean) => {
                        commit('isTabFocused', value)
                    })
        
                    socket.on(SOCKETIO_EVENTS.IS_SHARING_LOCATION, (value: Boolean) => {
                        commit('isSharingLocation', value)
                        if(value === false) {
                            commit('log', 'Caller Stopped Sharing')
                        }
                    })
        
                    socket.on(SOCKETIO_EVENTS.LOCATION_SHARING_ERROR, (data: any) => {
                        commit('log', `Caller unable to share location: ${data}`)
                    })
        
                    socket.on('connect_timeout', (location: Location) => commit('status', 'connect_timeout'))
                    socket.on('disconnect', (location: Location) => commit('status', 'Disconnected'))
                    socket.on('reconnect_attempt', (location: Location) => commit('status', 'Attempting to Reconnect'))
                    socket.on('reconnect', (location: Location) => commit('status', 'reconnect'))
                    socket.on('error', (location: Location) => commit('status', 'error'))
                    socket.on('reconnect_error', (location: Location) => commit('status', 'Attempting to Reconnect'))
                    socket.on('reconnect_failed', (location: Location) => commit('status', 'Unable to Reconnect'))
                }
                })
            //axios.get(`api/v1/location/get-connect-socket/${state.caseTransferUuid}`)
            //.then((response) => {
            //})

        },

        async emitLatestLocation({dispatch, commit, state}, location: Location){
            axios.get(`api/v1/location/emit-latest-location/${state.caseTransferUuid}/${location}`)
        },

        //Print down the location and check for addLocation function if working

        async getLatestLocations({dispatch, commit, state}){
            axios.get(`api/v1/location/get-latest-location/:${state.connectionRequest.id}`)
            .then((response) => {
                commit('log', response.data.latitude)
                dispatch('emitLatestLocation', response.data)
                commit('addLocation', response.data)
            })
        },

        async latestLocation({state}){
            return state.locations[state.locations.length - 1]
            //pops off the last location of 
        },
        async chatInitiated({state}){
            if(state.chatInitiated) axios.post(`api/v1/location/chat-initiated/${state.connectionRequest.uuid}`) 
        }
    }
}

export default LocationModule

export interface LocationTypes {
    mutations: {
        'location/showSmsConfirmation': boolean
        'location/phoneNumber': string
        'location/showEmailVerificationModal': boolean
        'location/showClearedDomainModal': boolean
        'location/showEditEmailModal': boolean
        'location/showCallTransferModal': boolean
        'location/showAcceptCallTransferModal' : boolean
        'location/showCaseTransferModal': boolean
        'location/showFindByNumbersModal': boolean
        'location/showFindByListModal': boolean
        'location/showCreateCallListModal': boolean
        'location/showAddUsersToCLModal': boolean
        'location/showServiceAlertModal': boolean
        'location/showAddNumbersModal': boolean
        'location/showUserProfileModal': boolean
        'location/changeCaseTransferUuid' : string
        'location/addPhoneNumber': string
        'location/callList': any
        'location/callListNumbers': any
        'location/newNumbers' : any
        'location/setLanguage': string
        'location/setDeepLLanguage': string
        'location/showA2PMessagingModal': boolean
        'location/showA2PMobileMessagingModal': boolean
        'location/showA2POverwatchModal': boolean
        'location/chatInitiated': boolean
    }
    actions: {
        'location/requestLocation': {}
        'location/requestLocationSms': {}
        'location/reset': {}
        'location/downloadLocations': {}
        'location/acceptCaseTransfer': {}
        'location/denyCaseTransfer': {}
        'location/requestTransfer' : {}
        'location/checkTransfer' : {}
        'location/checkCaseTransferConfirmation': {}
        'location/connectToSocket': {}
        'location/requestMultipleLocations': {}
        'location/requestNewNumberLocations': {}
        'location/latestLocation': {}
        'location/uploadToS3': {}
        'location/showA2PMessagingModal': {}
        'location/chatInitiated': {}
    }
}

