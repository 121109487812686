<template>
    <div class="text-xs-center">
        <v-dialog v-model="dialog" width="500">
            <v-card>
                <v-card-title class="headline" primary-title>i911 Privacy Policy</v-card-title>

                <v-card-text> <PrivacyPolicy /> </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="accepted">I accept</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Vue from 'vue'
import PrivacyPolicy from '@/pages/Home/PrivacyPolicy.vue'

export default Vue.extend({
    props: {
        value: Boolean
    },
    computed: {
        dialog: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        accepted() {
            this.$emit('accepted')
            this.dialog = false
        }
    },
    components: {
        PrivacyPolicy
    }
})
</script>
