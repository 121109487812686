<template>
    <v-flex class="hero-section__left" md8 sm12 :pl-0="!smAndDown" :pa-5="!smAndDown" :pa-2="smAndDown">
        <v-layout class="hero-section__header" tag="header" align-center justify-left>
            <img class="logo" v-if="!smAndDown" :src="require('@/assets/i911-logo-new.png')" />
            <img class="logo" v-else :src="require('@/assets/i911-logo-new.png')" />
            <MainNav v-if="smAndDown" />
            <div class="slogan" style="text-transform: capitalize;font-weight:700;" v-if="!smAndDown">
                <span class="headline" style="text-transform: capitalize;font-weight:700;">Accurately locate persons in distress</span>
                <span class="subheading">Take the "search" out of search and rescue</span>
            </div>
        </v-layout>
        <HeroContent />
    </v-flex>
</template>

<script lang="ts">
import Vue from 'vue'

import HeroContent from './HeroContent.vue'
import MainNav from './MainNav.vue'

export default Vue.extend({
    computed: {
        smAndDown(): boolean {
            return this.$vuetify.breakpoint.smAndDown
        }
    },
    components: {
        MainNav,
        HeroContent
    }
})
</script>

<style lang="scss" scoped>
.hero-section__left {
    position: relative; // padding: $sectionPadding;
    // padding-top: 3rem;
    color: white;
    z-index: 0; //Required to be set to allow :before to sit behind.
    // Left side color bleed. Allows pink background.
    &:before {
        content: '';
        z-index: -1; // puts this behind its parent
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 999999px; // bleeds color outside of container to left.
        background-color: #1876D1;
    }
}

.hero-section__header {
    margin-bottom: 2rem;
    @media (max-width: 960px) {
        display: flex;
        margin-bottom: 0rem;
        justify-content: space-between;
    }
}

.logo {
    width: 10.2rem;
    z-index: -1;
    @media (max-width: 960px) {
        height: 3rem;
        width: auto;
    }
}

.slogan {
    margin-bottom: 1rem;
    margin-left: 3rem;
    * {
        display: block;
    }
    @media (max-width: 1264px) {
        margin-left: 1rem;
        .headline {
            font-size: 1.4em !important;
            font-weight: bold;
        }
        .subheading {
            font-size: 1rem !important;
        }
    }
    @media (max-width: 960px) {
        margin: 0;
        margin-bottom: 2rem;
        & > * {
            text-align: center;
        }
    }
}
</style>
