<template>
    <v-app>
        <v-app-bar app color="#231f20" dark>
            <v-layout justify-space-between fill-height align-center>
                <v-flex sm6 md3>
                    <LogoSmall />
                </v-flex>
                <v-flex md6 v-if="!smAndDown">
                    <v-layout justify-center align-center>
                        <!-- <div> -->
                        <form v-if="log.length === 0" @submit.prevent="requestLocation">
                            <v-layout>
                                <v-text-field
                                    data-v-step="1"
                                    v-model="phoneNumber"
                                    name="phoneNumber"
                                    flat
                                    solo
                                    mask="phone"
                                    label="Phone number"
                                    light
                                    style="border-top-right-radius:0;border-bottom-right-radius:0"
                                    autofocus
                                    hide-details
                                    data-test="phone-number-input"
                                />
                                <v-btn
                                    type="submit"
                                    outlined
                                    dark
                                    style="margin:0 0 0 -2px;height:48px;"
                                    data-test="start-locating-button"
                                    >Locate</v-btn
                                >
                            </v-layout>
                            <v-snackbar
                                v-model="showErrorSnackbar"
                                :timeout="3000"
                                top
                                left
                                data-test="valid-phone-number-error-snackbar"
                                >Please use a valid Phone Number</v-snackbar
                            >
                        </form>
                        <template v-else>
                            <span style="margin-right:10px">Locating: {{ phoneNumber }}</span>

                            <span class="end-tracking-container">
                                <EndTrackingModal>
                                    <v-btn outlined dark data-test="end-tracking-button">End Tracking</v-btn>
                                </EndTrackingModal>
                            </span>
                        </template>
                    </v-layout>
                    <!-- </div> -->
                </v-flex>
                <v-flex style="display: flex; align-items: center; justify-content: flex-end;" sm6 md3>
                    <template v-if="customHeaderLinks">
                        <v-btn
                            text
                            dark
                            v-for="(link, index) in customHeaderLinks"
                            :key="index"
                            :href="link.link"
                            target="_blank"
                        >{{ link.name }}</v-btn>
                    </template>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                            <div style="display: flex; align-items: center;">
                                <span>{{ user.firstName }} {{ user.lastName }}</span>
                                <v-btn v-on="on" large text icon>
                                    <v-icon large>$vuetify.icons.user_circle</v-icon>
                                </v-btn>
                            </div>
                        </template>
                        <v-list light>
                        <v-list-item @click="toProfile">
                            <v-list-item-title>Profile</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="isAdminOrTeamAdmin" :to="{ name: 'admin' }">
                            <v-list-item-title>Admin</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="premiere" :to="{ name: 'overwatch' }">
                            <v-list-item-title>Overwatch</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="toTraining">
                            <v-list-item-title>Training Videos</v-list-item-title>
                        </v-list-item>
                        <v-list-item href="https://i911help.zendesk.com/hc/en-us/sections/360009792793-FAQ" target="_blank">
                            <v-list-item-title>FAQ</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="signOut">
                            <v-list-item-title>Sign Out</v-list-item-title>
                        </v-list-item>
                        <template v-if="customHeaderDropdownLinks">
                            <v-list-item v-for="(link, index) in customHeaderDropdownLinks" :key="index" :href="link.link" target="_blank">
                                <v-list-item-title>{{ link.name }}</v-list-item-title>
                            </v-list-item>
                        </template>
                        </v-list>
                    </v-menu>
                </v-flex>
            </v-layout>
        </v-app-bar>
        <v-main>
            <v-alert v-if="seen" class="alert" :style="{ position: 'fixed', top: 50, zIndex:1 }"><p class="sms-text">SMS Received</p></v-alert>
            <slot />
        </v-main>
        <!-- <DashboardTour /> -->
    </v-app>
</template>

<script lang="ts">
import Vue from 'vue'
import store from '@/store'
import {eventBus} from "@/main";
import axios from 'axios';

// Must be high in the componnet heirarchy for correct DOM positioning.
import DashboardTour from '@/components/DashboardTour.vue'
import EndTrackingModal from '@/components/EndTrackingModal.vue'

export default Vue.extend({
    data() {
        return {
            showErrorSnackbar: false,
            teamName: null,
            seen: false,
            premiere: false
        }
    },
    created() {
        this.teamName = store.state.authentication.currentUser.companyName
        eventBus.$on('triggerSmsBanner', () => {
            this.triggerSmsBanner()
        })

        const level = "premiere"
        axios.get(`/api/v1/teams/checkPermissionLevel/${this.teamName}/${level}`)
        .then(res => {
            this.premiere = res.data //returns either a 1 or 0 (T/F)
        })
    },
    methods: {
        async requestLocation() {
            const isValid = await this.$validator.validateAll()
            if (isValid) {
                store.dispatch('location/requestLocation')
                // this.$tours.dashboardTour.stop()
            } else {
                this.showErrorSnackbar = true
            }
        },
        toProfile() {
            store.commit('location/showUserProfileModal', true)
        },
        signOut() {
            store.dispatch('authentication/logout')
            store.dispatch('location/reset')
            // this.$tours.dashboardTour.stop()
            this.$router.push({
                name: 'auth-sign-in'
            })
        },
        triggerSmsBanner() {
            this.seen = true;
            setTimeout(() => {
                this.seen = false;
            }, 3000);
        },
        toTraining() {
            this.$router.push({
                name: 'training'
            })
        },
    },
    computed: {
        phoneNumber: {
            get(): string {
                return store.state.location.phoneNumber
            },
            set(value: string) {
                store.commit('location/phoneNumber', value)
            }
        },
        log(): any {
            return store.state.location.log
        },
        smAndDown(): any {
            return this.$vuetify.breakpoint.smAndDown
        },
        user(): any {
            return store.state.authentication.currentUser
        },
        customHeaderDropdownLinks(): any {
            const teams = store.state.authentication.teams
            if (!teams || teams.length == 0) return []

            const team = teams[0]
            const links = team.customContent && team.customContent.links && team.customContent.links.headerDropdown
            if (links) return links
            return []
        },
        customHeaderLinks(): any {
            const teams = store.state.authentication.teams
            if (!teams || teams.length == 0) return []

            const team = teams[0]
            const links = team.customContent && team.customContent.links && team.customContent.links.header
            if (links) return links
            return []
        },
        isAdminOrTeamAdmin() {
            const user = store.state.authentication.currentUser
            return user && (user.isAdmin || user.isTeamAdmin)
        },
        a2pModal() {
            return store.state.location.showA2PMessagingModal
        }
    },
    components: {
        // DashboardTour,
        EndTrackingModal
    }
})
</script>
<style lang="scss">
.v-step {
    z-index: 100;
}

// .location-number-container {
//     @media (max-width: 1244px) {
//         position: relative;
//         float: left;
//         width: 70%;
//         top: 15px;
//         margin: 0;
//     }
// }

// .end-tracking-container {
//     @media (max-width: 1244px) {
//         position: relative;
//         float: right;
//         width: 30%;
//         margin: 0;
//     }
// }

.alert {
  width: 100%;
  height: 4% !important;
  background-color:#FF0000 !important;
  border-radius:0 !important;
  padding-top: 7px !important;
}

.sms-text {
  color: white !important;
  font-weight: bold;
  font-size: 19px;
  text-align: center;
  margin-bottom: 20px !important;
}
</style>
