import axios from 'axios'

export interface User {
    id: number
    email: string
    firstName: string
    lastName: string
    phoneNumber: string
    state: string
    companyName: string
    departmentName: string
    emailIsVerified: boolean
    dateTimeCreated: number
    psapAffiliationHasBeenVerified: boolean
    psapId: number
    isAdmin: boolean
    options?: {
        locationFormat?: 'LatLong' | 'DMS' | 'DDM'
    }
}

interface EditUserPayload {
    email?: string
    firstName?: string
    lastName?: string
    phoneNumber?: string
    state?: string
    companyName?: string
    departmentName?: string
    hasSeenWalkthrough?: boolean
    isAdmin?: boolean
    options?: {
        locationFormat?: 'LatLong' | 'DMS' | 'DDM'
    }
}

export const editUser = (id: number, payload: EditUserPayload) => axios.patch<User>(`/api/v1/users/${id}`, payload)

export const findUsers = () => axios.get<User[]>(`/api/v1/users/`)

export const findClientUsers = (month: number | string, year: number | string) => axios.get(`/api/v1/users/find-client-users/${month}/${year}`)

export const findNewClientUsers = (month: number | string, year: number | string) => axios.get(`/api/v1/users/find-new-client-users/${month}/${year}`)

export const findPsapUsers = () => axios.get<User[]>(`/api/v1/auth/users/`)

export const searchUsers = (searchText: string) => axios.get<User[]>(`/api/v1/users/search?search=${searchText}`)

export const findUnlistedUsers = () => axios.get(`/api/v1/users/find-unlisted-users`)