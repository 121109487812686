export const isCallyoUscgTest = (email: string) => {
    return email.endsWith('-uscg@callyo.com')
}

export const isUscgEmail = (email: string) => {
    const isUscg = email.endsWith('@uscg.mil')
    const isCallyoTestEmail = isCallyoUscgTest(email)

    return isUscg || isCallyoTestEmail
}

export const convertCallyoUscgTestToRealEmail = (email: string) => {
    return email.replace('-uscg', '')
}
