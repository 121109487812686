<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="76"
        height="38"
        viewBox="0 0 76 38"
    >
        <defs>
            <path
                id="a"
                d="M28.5 22.46a6.23 6.23 0 0 0-6.16 6.29 6.23 6.23 0 0 0 6.16 6.29c3.4 0 6.17-2.82 6.17-6.3a6.23 6.23 0 0 0-6.17-6.28zm-4.87 18.03a12.8 12.8 0 0 1-7.38-14.27A12.56 12.56 0 0 1 28.5 16c4.67 0 8.95 2.65 11.1 6.88a12.97 12.97 0 0 1-.88 13.22L28.5 54V41.5c-1.67 0-3.33-.34-4.87-1z"
            ></path>
            <path
                id="b"
                d="M62 26.5c-1.4 0-2.66.83-3.2 2.1a3.38 3.38 0 0 0 .76 3.72 3.49 3.49 0 0 0 3.76.74A3.41 3.41 0 0 0 62 26.49zm-2.73 9.76A6.88 6.88 0 0 1 62 23a7.03 7.03 0 0 1 5.83 3.09 6.82 6.82 0 0 1-.1 7.79L62 44v-7.2c-.94 0-1.86-.18-2.73-.54z"
            ></path>
            <path
                id="c"
                d="M53.43 29.47V44H49.5V29.47zm.57-4.23c0 .29-.07.58-.2.85a2.61 2.61 0 0 1-2.33 1.35 2.58 2.58 0 0 1-1.74-.66 2.3 2.3 0 0 1-.53-.7 1.99 1.99 0 0 1 0-1.73c.12-.26.3-.5.53-.7a2.57 2.57 0 0 1 1.74-.65 2.68 2.68 0 0 1 1.77.65c.24.2.42.44.55.7.14.29.21.58.21.89z"
            ></path>
            <path
                id="d"
                d="M72.26 42.06c.42 0 .84-.06 1.25-.19.28-.1.5-.33.67-.69.16-.35.24-.9.24-1.67V27.75l-3.32 1.9L70 27.9l6.98-4.9h1.83v16.19c0 1.25-.03 2.13-.1 2.64.65-.06 1.42-.09 2.29-.09V44h-8.74z"
            ></path>
            <path
                id="e"
                d="M83.25 42.06c.43 0 .85-.06 1.25-.19.29-.1.51-.33.67-.69.17-.35.25-.9.25-1.67V27.75l-3.32 1.9L81 27.9l6.98-4.9h1.82v16.19c0 1.25-.03 2.13-.09 2.64.65-.06 1.42-.09 2.29-.09V44h-8.75z"
            ></path>
        </defs>
        <use :fill="iconFill" xlink:href="#a" transform="translate(-16 -16)"></use>
        <use :fill="textFill" xlink:href="#b" transform="translate(-16 -16)"></use>
        <use :fill="textFill" xlink:href="#c" transform="translate(-16 -16)"></use>
        <use :fill="textFill" xlink:href="#d" transform="translate(-16 -16)"></use>
        <use :fill="textFill" xlink:href="#e" transform="translate(-16 -16)"></use>
    </svg>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
    props: {
        // For use in a light context
        light: {
            type: Boolean
        }
    },
    computed: {
        iconFill() {
            if (this.light) {
                return '#00aeef'
            }
            return '#00aeef'
        },
        textFill() {
            if (this.light) {
                return '#222'
            }
            return '#fff'
        }
    }
})
</script>
