import Vue from 'vue'

export function secondsToHms(datetime: number): string {
    const time = new Date(datetime)
    const hours = time.getHours()
    const minutes = time.getMinutes()
    const seconds = time.getSeconds()
    let timeString = '' + (hours > 12 ? hours - 12 : hours)
    timeString += (minutes < 10 ? ':0' : ':') + minutes
    timeString += (seconds < 10 ? ':0' : ':') + seconds
    timeString += hours >= 12 ? ' pm' : ' am'
    return timeString
}

Vue.filter('secondsToHms', function(value: number) {
    if (value) {
        return secondsToHms(value)
    }
})
