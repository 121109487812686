<template>
    <nav class="nav">
        <ul>
            <li v-if="!smAndDown">
                <a href="https://i911help.zendesk.com/hc/en-us/sections/360009792793-FAQ" target="_blank">FAQ</a>
            </li>
            <li><router-link :to="{ name: 'auth-sign-in' }">Sign In</router-link></li>
            <li v-if="smAndDown"><a href="#register-form" class="sign-up">Sign Up</a></li>
        </ul>
    </nav>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
    computed: {
        smAndDown(): boolean {
            return this.$vuetify.breakpoint.smAndDown
        }
    }
})
</script>

<style lang="scss" scoped>
.nav {
    display: flex;
    margin-bottom: 1rem;
    justify-content: flex-end;
    ul {
        display: inline-block;
        padding: 0;
        li {
            display: inline-block;
            list-style: none;
            a {
                display: inline-block;
                padding: 0.8rem;
                color: black;
                font-size: 1.2rem;
                text-decoration: none;
                @media (max-width: 960px) {
                    color: white;
                    padding: 1rem;
                }
            }
            @media (min-width: 961px) {
                &:last-child a {
                    padding-right: 0;
                }
            }
            .sign-up {
                background-color: rgba(255, 164, 164, 0.3);
                border-radius: 8px;
                padding: 0.8rem;
            }
        }
    }
}
</style>
