import 'babel-polyfill'
import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import '@/router/middleware'
import store from '@/store'
import vuetify from '@/plugins/vuetify'
import '@/plugins/vee-validate'
import '@/plugins/mapbox'
import '@/plugins/vue-tour'
import '@/global-components'
import '@/utils/auth-axios-interceptor'
import '@/filters/date-format'
import '@/filters/seconds-to-hms'

import 'roboto-fontface'
import 'mapbox-gl/dist/mapbox-gl.css'

Vue.config.productionTip = false

export const eventBus = new Vue()

// if called within Vue Lifecyclehook, router beforeEach hook gets called first.
const run = async () => {
    await store.dispatch('authentication/startupCheck')

    new Vue({
        vuetify,
        router,
        store,
        render: (h) => h(App)
    }).$mount('#app')
}
run()
