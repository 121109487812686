import axios from 'axios'
import { CreateCallListTeamDto, UpdateCallListTeamDto } from '../../../server/src/call_list/call-list-team.dtos'
import { CreateCallListUserDto, UpdateCallListUserDto } from '../../../server/src/call_list/call-list-user.dtos'
import { CallListTeam } from '../../../server/src/call_list/call-list-team.entity'
import { CallListUser } from '../../../server/src/call_list/call-list-user.entity'

export type {
    CreateCallListTeamDto,
    UpdateCallListTeamDto,
    CreateCallListUserDto,
    UpdateCallListUserDto
};

export const getCallListsByTeam = (teamId: number | string) => axios.get<CallListTeam>(`/api/v1/call-list/get-team-call-lists/${teamId}`)

export const getCallListTeam = (teamId: number | string) => axios.get<CallListTeam>(`/api/v1/call-list/get-call-list-team/${teamId}`)

export const getTeamCLUsers = (clTeamId: number | string) => axios.get<CallListUser>(`/api/v1/call-list/get-team-cl-users/${clTeamId}`)

export const getTeamNumbers = (clTeamId: number | string) => axios.get<CallListTeam>(`/api/v1/call-list/get-team-numbers/${clTeamId}`)

export const getDeployableCallList = (clTeamId: number | string) => axios.get<CallListUser>(`/api/v1/call-list/get-deployable-call-list/${clTeamId}`)

export const getAllCallListTeams = () => axios.get<CallListTeam>(`/api/v1/call-list/get-all-cl-teams`)

export const createCallList = (teamId: number | string, formData: Partial<CreateCallListTeamDto>) => {
    return axios.post<CallListTeam>(`/api/v1/call-list/create-call-list/${teamId}`, formData)
}

export const searchCallListUsers = (searchText: string) => axios.get<CallListUser>(`/api/v1/call-list/search?search=${searchText}`)

export const addExistingUsers = (userId: number, selectedId: number) => axios.put(`/api/v1/call-list/add-existing-users/${userId}/${selectedId}`)

export const removeExistingUsers = (userId: number) => axios.put(`/api/v1/call-list/remove-existing-users/${userId}`)

export const removeCalListTeam = (teamId: number) => axios.put(`/api/v1/call-list/remove-call-list-team/${teamId}`)

export const createNewUser = (clTeamId: number | string, formData: Partial<CreateCallListUserDto>) => { 
    axios.post(`/api/v1/call-list/add-new-user/${clTeamId}`, formData)
}