import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/pages/Home'
import AppLayout from '@/layouts/AppLayout.vue'
import AdminLayout from '@/layouts/AdminLayout.vue'
import OverwatchLayout from '@/layouts/OverwatchLayout.vue'
import TrainingLayout from '@/layouts/TrainingLayout.vue'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/auth/sign-in',
            name: 'auth-sign-in',
            component: () => import(/* webpackChunkName: "signin" */ '@/pages/SignIn.vue')
        },
        {
            path: '/auth/sign-out',
            name: 'auth-sign-out',
            meta: { auth: true },
            component: () => import(/* webpackChunkName: "signout" */ '@/pages/SignOut.vue')
        },
        {
            path: '/auth/forgot-password',
            name: 'auth-forgot-password',
            component: () => import(/* webpackChunkName: "forgot-password" */ '@/pages/ForgotPassword.vue')
        },
        {
            path: '/auth/forgot-password/sent',
            name: 'auth-forgot-password-sent',
            component: () => import(/* webpackChunkName: "forgot-password-sent" */ '@/pages/ForgotPasswordSent.vue')
        },
        {
            path: '/auth/forgot-password/reset',
            name: 'auth-forgot-password-reset',
            props(route) {
                return { resetCode: route.query['reset-code'] }
            },
            component: () => import(/* webpackChunkName: "forgot-password-reset" */ '@/pages/ResetPassword.vue')
        },
        {
            path: '/auth/forgot-password/success',
            name: 'auth-forgot-password-success',
            component: () =>
                import(/* webpackChunkName: "forgot-password-success" */ '@/pages/ResetPasswordSuccess.vue')
        },
        {
            path: '/register/email-confirmation',
            name: 'register-email-confirmation',
            props(route) {
                return { verificationCode: route.query['verification-code'] }
            },
            component: () =>
                import(/* webpackChunkName: "register-email-confirmation" */ '@/pages/RegisterEmailConfirmation.vue')
        },
        {
            path: '/register/email-confirmation/resend',
            name: 'register-resend-email-verification',
            component: () =>
                import(/* webpackChunkName: "register-email-resend" */ '@/pages/RegisterResendEmailVerification.vue')
        },
        {
            path: '/register/email-confirmation/email-sent',
            name: 'register-email-sent',
            component: () => import(/* webpackChunkName: "register-email-sent" */ '@/pages/RegisterEmailSent.vue')
        },
        {
            path: '/register/complete',
            name: 'register-complete',
            meta: { auth: true },
            component: () => import(/* webpackChunkName: "register-complete" */ '@/pages/RegisterComplete.vue')
        },
        {
            path: '/register/complete-uscg',
            name: 'register-complete-uscg',
            meta: { auth: true },
            component: () =>
                import(/* webpackChunkName: "register-complete-uscg" */ '@/pages/RegisterCompleteCoastGuard.vue')
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            meta: { auth: true, layout: AppLayout },
            component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/Dashboard')
        },
        {
            path: '/admin',
            name: 'admin',
            meta: { auth: true, admin: true, layout: AdminLayout },
            component: () => import(/* webpackChunkName: "admin" */ '@/pages/AdminUsers.vue')
        },
        {
            path: '/admin/users',
            name: 'admin-users',
            meta: { auth: true, admin: true, layout: AdminLayout },
            component: () => import(/* webpackChunkName: "admin-users" */ '@/pages/AdminUsers.vue')
        },
        {
            path: '/admin/teams',
            name: 'admin-teams',
            meta: { auth: true, admin: true, layout: AdminLayout },
            component: () => import(/* webpackChunkName: "admin-teams" */ '@/pages/AdminTeams.vue')
        },
        {
            path: '/admin/overwatch',
            name: 'admin-overwatch',
            meta: { auth: true, admin: true, layout: AdminLayout },
            component: () => import(/* webpackChunkName: "admin-team-edit" */ '@/pages/AdminOverwatch.vue')
        },
        {
            path: '/overwatch',
            name: 'overwatch',
            meta: { auth: true, admin: true, layout: OverwatchLayout },
            component: () => import(/* webpackChunkName: "admin-teams" */ '@/pages/Dashboard/desktop/Overwatch.vue')
        },
        {
            path: '/admin/overwatch/:id/edit',
            name: 'admin-overwatch-edit',
            meta: { auth: true, admin: true, layout: AdminLayout },
            component: () => import(/* webpackChunkName: "admin-team-edit" */ '@/pages/AdminOverwatchEdit.vue')
        },
        {
            path: '/admin/teams/:id/edit',
            name: 'admin-team-edit',
            meta: { auth: true, admin: true, layout: AdminLayout },
            component: () => import(/* webpackChunkName: "admin-team-edit" */ '@/pages/AdminTeamEdit.vue')
        },
        {
            path: '/admin/metrics',
            name: 'admin-metrics',
            meta: { auth: true, admin: true, layout: AdminLayout },
            component: () => import(/* webpackChunkName: "admin-teams" */ '@/pages/AdminMetrics.vue')
        },
        {
            path: '/admin/metrics/:id',
            name: 'admin-metrics-team',
            meta: { auth: true, admin: true, layout: AdminLayout },
            component: () => import(/* webpackChunkName: "admin-teams" */ '@/pages/AdminMetricsTeam.vue')
        },
        {
            path: '/admin/verification/edit',
            name: 'admin-verification-edit',
            meta: { auth: true, admin: true, layout: AdminLayout },
            component: () => import(/* webpackChunkName: "admin-team-edit" */ '@/pages/AdminVerificationEdit.vue')
        },
        {
            path: '/admin/dev',
            name: 'admin-dev',
            meta: { auth: true, admin: true, layout: AdminLayout },
            component: () => import(/* webpackChunkName: "admin-dev" */ '@/pages/AdminDev.vue')
        },
        {
            path: '/uscg/approval',
            name: 'uscg-approval',
            meta: { auth: true },
            component: () => import(/* webpackChunkName: "coast-guard-approval" */ '@/pages/CoastGuardApproval.vue')
        },
        {
            path: '/admin/platform-metrics',
            name: 'admin-platform-metrics',
            meta: { auth: true, admin: true, layout: AdminLayout },
            component: () => import(/* webpackChunkName: "admin-teams" */ '@/pages/AdminPlatformMetrics.vue')
        },
        {
            path: '/training',
            name: 'training',
            meta: { auth:true, layout: TrainingLayout },
            component: () => import(/* webpackChunkName: "training" */ '@/pages/Training.vue')
        },
    ]
})

export default router
