import axios from 'axios'

export interface SignInPayload {
    email: string
    password: string
    staySignedIn: boolean
}
export function signIn(payload: SignInPayload) {
    return axios.post(`/api/v1/auth/sign-in`, payload)
}

export interface RegisterPayload {
    firstName: string
    lastName: string
    email: string
    password: string
    captchaToken: string
    agreedToTermsOfService: boolean
}

export function register(payload: RegisterPayload) {
    return axios.post(`/api/v1/auth/register`, payload)
}

export interface GroupRegisterPayload {
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    password: string
    agreedToTermsOfService: boolean
}

export function groupRegister(payload: GroupRegisterPayload) {
    return axios.post(`/api/v1/auth/group-register`, payload)
}

export interface RegisterCompletePayload {
    psapPrimaryKey: number | null
    phoneNumber: string
    companyName: string | null
}

export function registerComplete(payload: RegisterCompletePayload) {
    return axios.post(`/api/v1/auth/complete-registration`, payload)
}

export function joinUnlisted() {
    return axios.post(`/api/v1/auth/join-unlisted`)
}

export interface RegisterCompleteUSCGPayload {
    teamId: number
}
export function registerCompleteUSCG(payload: RegisterCompleteUSCGPayload) {
    return axios.post(`/api/v1/auth/complete-registration-uscg`, payload)
}

export interface ResendEmailConfirmationPayload {
    email: string
}
export function resendEmailConfirmation(payload: ResendEmailConfirmationPayload) {
    return axios.post(`/api/v1/auth/resend-email-confirmation`, payload)
}

export interface ConfirmEmailPayload {
    uuid: string
}
export function confirmEmail(payload: ConfirmEmailPayload) {
    return axios.post(`/api/v1/auth/confirm-email`, payload)
}

export interface ForgotPasswordPayload {
    email: string
}
export function forgotPassword(payload: ForgotPasswordPayload) {
    return axios.post(`/api/v1/auth/forgot-password`, payload)
}

export interface UpdatePasswordPayload {
    uuid: string
    password: string
}
export function updatePassword(payload: UpdatePasswordPayload) {
    return axios.post(`/api/v1/auth/update-password`, payload)
}

interface CurrentUser {
    email: string
}
export function currentUser() {
    return axios.get<CurrentUser>(`/api/v1/auth/current-user`)
}
