import Vuex, { Module, ActionContext, Store } from 'vuex'
import axios, { AxiosResponse } from 'axios'
//@ts-ignore
import localStore from 'store'
import { RootState } from '@/store'
import { signIn, register, RegisterPayload, SignInPayload, currentUser, GroupRegisterPayload, groupRegister, joinUnlisted } from '@/services/authentication'
import { setJWT, getJWT } from '@/utils/jwt'
import { isValidNumberForRegion } from 'libphonenumber-js'
import { Team, getUserTeams } from '@/services/teams'

function saveToken(token: string) {
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        localStore.set('jwt', token)
    } else {
        delete axios.defaults.headers.common['Authorization']
    }
}

interface PSAP {
    // Database Id
    id: number
    // FCC provided PSAP id
    psapId: number
    name: string
    state: string
    county: string
    city: string
    dateRecordCreated: Date
}

export interface User {
    id: number
    firstName: string
    lastName: string
    email: string
    companyName: null | string
    dateTimeCreated: string
    departmentName: null | string
    emailIsVerified: boolean
    phoneNumber: string
    psapAffiliationHasBeenVerified: boolean
    psapId: number | null
    psap: PSAP | null
    hasSeenWalkthrough: boolean
    isTeamAdmin: boolean
    isAdmin: boolean
    options?: {
        locationFormat?: 'LatLong' | 'DMS' | 'DDM'
    }
}

export interface AuthenticationState {
    currentUser: User | null
    teams: Team[] | null
}

type AuthenticationConext = ActionContext<AuthenticationState, RootState>

const AuthenticationModule: Module<AuthenticationState, RootState> = {
    namespaced: true,
    state: {
        currentUser: null,
        teams: []
    },
    mutations: {
        currentUser(state, currentUser) {
            localStore.set('currentUser', currentUser)
            state.currentUser = currentUser
        },
        teams(state, teams) {
            localStore.set('teams', teams)
            state.teams = teams
        },
        clearCurrentUser(state) {
            localStore.remove('currentUser')
            localStore.remove('jwt')
            delete axios.defaults.headers.common['Authorization']
            state.currentUser = null
        },
        clearCurrentUserTeams(state) {
            localStore.remove('teams')
            state.teams = null
        }
    },
    actions: {
        async startupCheck({ dispatch, commit, state }): Promise<void> {
            const currentUser = localStore.get('currentUser')
            const teams = localStore.get('teams')
            const jwt = getJWT()

            if (jwt) {
                if (currentUser) commit('currentUser', currentUser)
                if (teams) commit('teams', teams)

                setJWT(jwt)
                await dispatch('getCurrentUser')
                await dispatch('getCurrentUserTeams')
            }
        },
        async signIn(
            { dispatch, commit, state },
            signinPayload: SignInPayload
        ): Promise<AxiosResponse<{ token?: string }>> {
            const response = await signIn(signinPayload)
            const token = response.data.token

            setJWT(token)
            await dispatch('getCurrentUser')
            await dispatch('getCurrentUserTeams')
            return response
        },
        async register({ dispatch, commit, state }, payload: RegisterPayload) {
            const response = await register(payload)
            const token = response.data.token

            setJWT(token)

            // Get current user again for most up to date data
            await dispatch('getCurrentUser')

            return response
        },
        async groupRegister({ dispatch, commit, state }, payload: GroupRegisterPayload) {
            const response = await groupRegister(payload)
            return response
        },
        async joinUnlisted() {
            const response = await joinUnlisted()
            return response
        },
        logout({ dispatch, commit, state }) {
            return new Promise((resolve, reject) => {
                commit('clearCurrentUser')
                commit('clearCurrentUserTeams')
                resolve(true)
            })
        },
        async getCurrentUser({ dispatch, commit, state }, data) {
            const response = await currentUser()
            const user = response.data
            commit('currentUser', user)
        },
        async getCurrentUserTeams({ commit, state }) {
            const { data } = await getUserTeams(state.currentUser.id)
            commit('teams', data)
        }
    }
}

export default AuthenticationModule

export interface authenticationTypes {
    mutations: {
        'authentication/currentUser': User
    }
    actions: {
        'authentication/startupCheck': {
            payload: null
            return: void
        }
        'authentication/signIn': {
            payload: SignInPayload
            return: Promise<AxiosResponse<{ token?: string }>>
        }
        'authentication/register': {
            payload: RegisterPayload
            return: any
        }
        'authentication/groupRegister': {
            payload: GroupRegisterPayload
            return: Promise<AxiosResponse<{ token?: string }>>
        }
        'authentication/joinUnlisted': {
            payload: null
            return: any
        }
        'authentication/logout': {
            payload: null
            return: any
        }
        'authentication/getCurrentUser': {
            payload: null
            return: any
        }
        'authentication/getCurrentUserTeams': {
            payload: null
            return: any
        }
    }
}
