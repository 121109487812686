import { User } from '@/services/users'
import axios from 'axios'
import { CreateTeamRequestDto, UpdateCustomContentDTO, UpdateTeamRequestDto } from '../../../server/src/team/team.dtos'
import { Team } from '../../../server/src/team/team.entity'
import { CustomContent } from '../../../server/src/team/custom-content.entity'
import { TeamUser, TeamUserRole } from '../../../server/src/team_user/team-user.entity'
import { Psap } from './psaps'

export type {
    Team,
    CreateTeamRequestDto,
    UpdateTeamRequestDto,
    UpdateCustomContentDTO
}

export const findTeams = () => {
    return axios.get<Team[]>(`/api/v1/teams/`)
}

export const createTeam = (team: Partial<CreateTeamRequestDto>) => {
    return axios.post<Team>(`/api/v1/teams`, team)
}

export const getTeam = (teamId: number | string) => {
    return axios.get<Team>(`/api/v1/teams/${teamId}`)
}

export const editTeam = (teamId: number | string, team: Partial<UpdateTeamRequestDto>) => {
    return axios.patch<Team>(`/api/v1/teams/${teamId}`, team)
}
export const editCustomContent = (teamId: number | string, content: Partial<UpdateCustomContentDTO>) => {
    return axios.patch<CustomContent>(`/api/v1/teams/editCustomContent/${teamId}`, content)
}

export const getCustomContent = (teamId: number | string) => {
    return axios.get<CustomContent>(`/api/v1/teams/getCustomContent/${teamId}`)
}

export const deleteTeam = (teamId: number | string) => {
    return axios.patch<Team>(`/api/v1/teams/${teamId}`)
}

export const getTeamUsers = (teamId: number | string) => axios.get<User[]>(`/api/v1/teams/${teamId}/users`)

export const addUserToTeam = (teamId: number | string, userId: number | string, role: TeamUserRole) =>
    axios.post(`/api/v1/teams/${teamId}/users/${userId}`, { role })

export const getTeamUser = (teamId: number | string, userId: number | string) =>
    axios.get<TeamUser>(`/api/v1/teams/${teamId}/users/${userId}`)

export const editTeamUser = (teamId: number | string, userId: number | string, teamUser: Partial<TeamUser>) =>
    axios.patch<TeamUser>(`/api/v1/teams/${teamId}/users/${userId}`, teamUser)

export const removeUserFromTeam = (teamId: number | string, userId: number | string) =>
    axios.delete(`/api/v1/teams/${teamId}/users/${userId}`)

export const getTeamPsap = (teamId: number | string) => axios.get<Psap>(`/api/v1/teams/${teamId}/psap`)

export const setTeamPsap = (teamId: number | string, psapId: number | string) =>
    axios.post<Psap>(`/api/v1/teams/${teamId}/psap/${psapId}`)

export const removeTeamPsap = (teamId: number | string) => axios.delete(`/api/v1/teams/${teamId}/psap`)

export const getUserTeams = (userId: number | string) => axios.get(`/api/v1/users/${userId}/teams`)

export const getCTTotals = (teamId: number | string, month: number | string, year: number | string) => axios.get(`/api/v1/location/get-ct-totals/${teamId}/${month}/${year}`)

export const getUsage = (teamId: number | string, month: number | string, year: number | string) => axios.get(`/api/v1/location/get-usage/${teamId}/${month}/${year}`)

export const getDailyTotals = (teamId: number | string, month: number | string, year: number | string) => axios.get(`/api/v1/location/get-daily-totals/${teamId}/${month}/${year}`)

export const getMonthlyTotals = (teamId: number | string, year: number | string) => axios.get(`/api/v1/location/get-monthly-totals/${teamId}/${year}`)

export const getUserSearchTotals = (teamId: number | string, month: number | string, year: number | string) => axios.get(`/api/v1/location/get-user-search-totals/${teamId}/${month}/${year}`)

export const getUncertaintyRadiusTotals = (teamId: number | string, month: number | string, year: number | string) => axios.get(`/api/v1/target-location/get-uncertainty-radius-totals/${teamId}/${month}/${year}`)

export const getTotalLogins = (teamId: number | string, month: number | string, year: number | string) => axios.get(`/api/v1/login/get-total-logins/${teamId}/${month}/${year}`)

export const getCaseDurations = (teamId: number | string, month: number | string, year: number | string) => axios.get(`/api/v1/location/get-case-durations/${teamId}/${month}/${year}`)

export const getCaseTimeInterval = (teamId: number | string, month: number | string, year: number | string) => axios.get(`/api/v1/location/get-case-time-interval/${teamId}/${month}/${year}`)

export const getLanguages = (teamId: number | string, month: number | string, year: number | string) => axios.get(`/api/v1/location/get-languages/${teamId}/${month}/${year}`)

export const getChatTotals = (teamId: number | string, month: number | string, year: number | string) => axios.get(`/api/v1/location/get-chat-totals/${teamId}/${month}/${year}`)

export interface GroupRegisterPayload {
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    password: string
    agreedToTermsOfService: boolean
}

export const groupRegister = (user: GroupRegisterPayload) => { axios.post<Team>(`/api/v1/teams`, user)}

export const getHistoricalSearches = (teamId: number | string, year: number | string) => axios.get(`/api/v1/location/get-historical-searches/${teamId}/${year}`)

export const getUserTeam = (userId: number | string) => axios.get(`/api/v1/teams/get-user-team/${userId}`)

export const getUserLogins = (userId: number | string, month: number | string, year: number | string) => axios.get(`/api/v1/login/get-user-logins/${userId}/${month}/${year}`)

export const getUserTotalUsage = (teamId: number, month: number | string, year: number | string) => axios.get(`/api/v1/location/get-user-total-usage/${teamId}/${month}/${year}`)

export const getUserStatistics = (teamId: number, month: number | string, year: number | string) => axios.get(`/api/v1/location/get-user-statistics/${teamId}/${month}/${year}`)

export const getUserLanguages = (teamId: number, month: number | string, year: number | string) => axios.get(`/api/v1/location/get-user-languages/${teamId}/${month}/${year}`)

export const getUserDailyTotals = (teamId: number | string, month: number | string, year: number | string) => axios.get(`/api/v1/location/get-user-daily-totals/${teamId}/${month}/${year}`)

export const getUserCalendarTotals = (teamId: number | string, year: number | string) => axios.get(`/api/v1/location/get-user-calendar-totals/${teamId}/${year}`)