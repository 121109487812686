/** Stream service event names */
export enum STREAM_EVENTS {
    /** Used for injesting new SMS sourcesd location input events */
    NEW_TEXT_MESSAGE_INPUT = 'new-text-message-input',
    NEW_TEXT_MESSAGE_INPUT_CASE_TRANSFER = 'new-text-message-input-case-transfer',
    /** Used for injesting new rapisos location input events */
    NEW_RAPIDSOS_LOCATION = 'new-rapidsos-location',
    /** Used for tracking whether a caller is sharing location */
    IS_SHARING_LOCATION = 'is-sharing-location',
    /** Used for tracking whether a caller is connected to socketio */
    CALLER_CONNECTED = 'caller-connected',
    /** Used for tracking whether a caller has the browser tab focused */
    IS_TAB_FOCUSED = 'is-tab-focused',
    /** Used for tracking whether a caller had an error sharing location */
    LOCATION_SHARING_ERROR = 'location-sharing-error',
    /** Used for injesting and reemitting events for socketio */
    SOCKET_IO = 'socketio',
    /** When you need to emit an event but don't want it to have an effect  */
    DO_NOTHING = 'donothing'
}

/** Streamservice events caught and acted on by the socketio listeners */
export enum SOCKETIO_EVENTS {
    /** Used for client to sent new text message sourced locations to socketio server */
    TEXT_MESSAGE_LOCATION_UPDATE = 'text-message-location-update',
    /** Used for emiting new locations to socketio */
    NEW_LOCATION_AVAILABLE = 'new-location-available',
    /** Used for tracking whether a caller is sharing location */
    IS_SHARING_LOCATION = 'is-sharing-location',
    /** Used for tracking whether a caller is connected to socketio */
    CALLER_CONNECTED = 'caller-connected',
    /** Used for tracking whether a caller has the browser tab focused */
    IS_TAB_FOCUSED = 'is-tab-focused',
    /** Used for tracking whether a caller had an error sharing location */
    LOCATION_SHARING_ERROR = 'location-sharing-error',
    /** Used for notifying the client they they have finished conncting to the server */
    CONNECT_COMPLETE = 'connect-complete',
    /** Used for notifying the client that they need to reconnect with a different token */
    UPDATE_CONNECTION_TOKEN = 'update-connection-token'
}
