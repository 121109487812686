<template>
    <div id="app" :is="layout"><router-view :key="$route.fullpath" /></div>
</template>

<script lang="ts">
import Vue, { Component } from 'vue'
import AuthLayout from '@/layouts/AuthLayout.vue'
import EmptyLayout from '@/layouts/EmptyLayout.vue'
export const eventBus = new Vue();

export default Vue.extend({
    components: {
        AuthLayout
    },
    computed: {
        layout(): Component {
            if (this.$route.meta && typeof this.$route.meta.layout !== 'undefined') {
                const layout = this.$route.meta.layout
                if (!layout) {
                    return EmptyLayout
                }
                return layout
            }

            return AuthLayout
        }
    },
    // loading recaptcha prior to the bundle causes issues with internet explorer 11, so we instead inject it later
    // https://github.com/zloirock/core-js/issues/566#issuecomment-514667729
    mounted(): void {
        const script = document.createElement('script')
        script.setAttribute(
            'src',
            'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit'
        )
        script.setAttribute('async', 'true')
        script.setAttribute('defer', 'true')
        document.body.appendChild(script)
    }
})
</script>

<style lang="scss">
// @import '@/styles/global.scss';
body {
    background-color: #eee;
}
</style>
