import Vuex, { StoreOptions } from 'vuex'

interface defaultMod {
    actions: {}
    mutations: {}
    getters: {}
}

export default class Store<S, M extends defaultMod> extends Vuex.Store<S> {
    constructor(options: StoreOptions<S>) {
        super(options)
    }
    //@ts-ignore
    commit = <T extends keyof M['mutations']>(type: T, payload: M['mutations'][T], options?: any): void => {
        //@ts-ignore
        return super.commit(type, payload, options)
    }
    //@ts-ignore
    dispatch = <T extends keyof M['actions']>(
        type: T,
        //@ts-ignore
        payload?: M['actions'][T]['payload'],
        options?: any
        //@ts-ignore
    ): M['actions'][T]['return'] => {
        //@ts-ignore
        return super.dispatch(type, payload, options)
    }

    // gettersSafe = <T extends keyof M['getters']>(type: T): M['getters'][T] => {
    //     return super.getters[type]
    // }
}
