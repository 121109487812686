import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'

import TypedStore from '@/utils/typed-store'
import authentication, { AuthenticationState, authenticationTypes } from './authentication'
import location, { LocationState, LocationTypes } from './location'
Vue.use(Vuex)

export interface RootState {
    authentication?: AuthenticationState
    location?: LocationState
}

export interface rootTypes {
    actions: {}
    mutations: {}
    getters: {}
}

export type storeTypes = rootTypes & authenticationTypes & LocationTypes

const store = new TypedStore<RootState, storeTypes>({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        authentication,
        location
    }
})

export default store
